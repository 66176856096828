import { useEffect } from "react";
import PropTypes from "prop-types";

import "./CommentEditor.css";

// mui styles
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";

export default function CommentEditor({
  onContentChange,
  resetContent,
  previousContent,
  setIsEmpty,
}) {
  CommentEditor.propTypes = {
    onContentChange: PropTypes.func,
    resetContent: PropTypes.bool,
    previousContent: PropTypes.any,
    setIsEmpty: PropTypes.func,
  };

  CommentEditor.defaultProps = {
    onContentChange: () => {},
    resetContent: false,
    previousContent: " ",
  };

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: "editor",
      },
    },
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: previousContent,
    // triggered on every change
    onUpdate: ({ editor }) => {
      onContentChange(editor.getJSON(), editor.getHTML());
      setIsEmpty(editor.isEmpty);
    },
  });

  useEffect(() => {
    if (resetContent) {
      editor.commands.clearContent();
    }
  }, [resetContent, editor]);

  return (
    <div>
      <div className="editor-textarea">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
}
