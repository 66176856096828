import { useAvatar } from "hooks/useAvatar";

// MUI styles
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useAlert from "hooks/useAlert";

export default function EditAvatar({ url, size, onUpload }) {
  const theme = useTheme();
  const { avatarUrl, upload, error, uploading, clear } = useAvatar(url);
  const { setAlert } = useAlert();

  const handleUpload = async (event) => {
    const filepath = await upload(event);
    if (error) {
      setAlert(error, "error");
    }
    onUpload(filepath);
  };

  const handleClear = () => {
    onUpload(null);
    clear();
  };

  return (
    <Stack direction="row" spacing={2}>
      <Box p={2}>
        <Avatar
          src={avatarUrl ? avatarUrl : ""}
          variant={"circular"}
          sx={{
            width: theme.spacing(20),
            height: theme.spacing(20),
          }}
        />
      </Box>
      <Box sx={{ alignItems: "flex-start" }} p={2}>
        {uploading ? (
          "Uploading..."
        ) : (
          <div className="visually-hidden">
            <input
              type="file"
              id="single"
              accept="image/*"
              onChange={handleUpload}
              disabled={uploading}
            />

            <Button variant="contained" onClick={handleClear}>
              Clear
            </Button>
          </div>
        )}
      </Box>
    </Stack>
  );
}
