import { useEffect } from "react";
import PropTypes from "prop-types";

import "./PostEditor.css";

// mui styles
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import IconButton from "@mui/material/IconButton";

// mui icons
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
        aria-label="bold"
      >
        <FormatBoldIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
        aria-label="italic"
      >
        <FormatItalicIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active" : ""}
        aria-label="strike-through"
      >
        <StrikethroughSIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
        aria-label="bullet list"
      >
        <FormatListBulletedIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
        aria-label="ordered list"
      >
        <FormatListNumberedIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        aria-label="undo"
      >
        <UndoIcon />
      </IconButton>
      <IconButton
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        aria-label="redo"
      >
        <RedoIcon />
      </IconButton>
    </>
  );
};

export default function PostEditor({
  onContentChange,
  resetContent,
  previousContent,
  setIsEmpty,
}) {
  PostEditor.propTypes = {
    onContentChange: PropTypes.func,
    resetContent: PropTypes.bool,
    previousContent: PropTypes.any,
    setIsEmpty: PropTypes.func,
  };

  PostEditor.defaultProps = {
    onContentChange: () => {},
    resetContent: false,
    previousContent: " ",
  };

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: "editor",
      },
    },
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: previousContent,
    // triggered on every change
    onUpdate: ({ editor }) => {
      onContentChange(editor.getJSON(), editor.getHTML());
      setIsEmpty(editor.isEmpty);
    },
  });

  useEffect(() => {
    if (resetContent) {
      editor.commands.setContent("");
    }
  }, [resetContent, editor]);

  return (
    <div>
      <div className="editor-menu">
        <MenuBar editor={editor} />
      </div>
      <div className="editor-textarea">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
}
