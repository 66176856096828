import { Link as RouterLink } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import Container from "theme/Container";
import DisplayAvatar from "shared/DisplayAvatar";
import { usePeople } from "hooks/useProfiles";

// MUI styles
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function People() {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { people, loading, error } = usePeople(user);

  return (
    <Container>
      <h1>People</h1>
      <Grid container spacing={4}>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {people &&
          people.map((profile, i) => (
            <Grid key={i} item xs={12} sm={4}>
              <Card
                sx={{
                  p: { xs: 2, md: 4 },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 1,
                  height: 1,
                  background: "transparent",
                  backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 75%, ${theme.palette.primary.main} 0%)`,
                }}
              >
                <DisplayAvatar
                  url={profile.avatarUrl ? profile.avatarUrl : null}
                  size={120}
                />
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  marginTop={2}
                >
                  <Typography fontWeight={700}>{profile.username}</Typography>
                </Box>
                {(profile.firstName || profile.lastName) && (
                  <Typography
                    color={"text.secondary"}
                  >{`${profile.firstName} ${profile.lastName}`}</Typography>
                )}
                <Box flexGrow={1} />
                <Stack
                  spacing={2}
                  marginTop={4}
                  width={1}
                  alignItems={"center"}
                >
                  <Button
                    variant={"outlined"}
                    color={"primary"}
                    component={RouterLink}
                    to={`/profiles/${profile.username}`}
                    fullWidth
                  >
                    View profile
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
