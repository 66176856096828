import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { goalPropType } from "propTypes/goalPropTypes";
import supabase from "../services/supabaseClient";

export const useGoals = (perPage = 3, start = 0, end = 9) => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(0);

  useGoals.propTypes = {
    goals: PropTypes.arrayOf(goalPropType),
  };

  function setGoalData(data) {
    PropTypes.checkPropTypes(
      { goals: PropTypes.arrayOf(goalPropType) },
      { goals: data },
      "goal",
      "setGoals"
    );
    setGoals(data);
  }

  useEffect(() => {
    const countPages = async () => {
      const { data, error } = await supabase
        .from("goals")
        .select("id", { count: "exact" });
      if (error) {
        setError("Goals not found.");
      } else {
        setNumPages(Math.ceil(data.length / perPage));
      }
    };
    countPages();
  }, [perPage]);

  useEffect(() => {
    const fetchGoals = async () => {
      setLoading(true);
      const { data, error } = await supabase
        .from("goals")
        .select("*, num_users:user_goal(count)")
        .is("is_approved", true)
        .order("title")
        .range(start, end);

      if (error) {
        setError("Goals not found.");
        setLoading(false);
      } else {
        const formattedGoals = data.map((item) => {
          return {
            id: item.id,
            createdAt: item.created_at,
            title: item.title,
            description: item.description,
            slug: item.slug,
            isApproved: item.is_approved,
            numUsers: item.num_users[0].count,
          };
        });

        setGoalData(formattedGoals);
        setLoading(false);
      }
    };
    fetchGoals();
  }, [start, end]);

  return {
    goals,
    loading,
    error,
    numPages,
  };
};
