import { createContext, useState, useEffect } from "react";
import supabase from "../services/supabaseClient";
import useAlert from "hooks/useAlert";
import { userGoalPropType } from "propTypes/userGoalPropTypes";
import PropTypes from "prop-types";

export const UserGoalsContext = createContext();

export const UserGoalsProvider = ({ userId, editing, children }) => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setAlert } = useAlert();

  function setGoalsData(data) {
    PropTypes.checkPropTypes(
      { goals: PropTypes.arrayOf(userGoalPropType) },
      { goals: data },
      "goals",
      "setGoals"
    );

    const filteredGoals = {
      openGoals: data.filter((goal) => !goal.isCompleted && !goal.isArchived),
      completedGoals: data.filter(
        (goal) => goal.isCompleted && !goal.isArchived
      ),
      archivedGoals: data.filter((goal) => goal.isArchived),
    };

    setGoals(filteredGoals);
  }

  useEffect(() => {
    if (userId) {
      const getUserGoals = async () => {
        try {
          const { data: goals, error } = await supabase
            .from("user_goal")
            .select(
              "*, profiles(username), goals(title, slug), num_likes:user_goal_likes(count), goal_stats(num_users), num_posts:blog_posts(count)"
            )
            .eq("user_id", userId)
            .order("order", { ascending: true });
          if (error) {
            throw error;
          }

          const formattedGoals = goals.map((item) => {
            return {
              id: item.id,
              createdAt: item.created_at,
              userId: item.user_id,
              username: item.profiles.username,
              goalId: item.goal_id,
              goalTitle: item.goals.title,
              goalSlug: item.goals.slug,
              numLikes: item.num_likes[0].count + 1,
              numUsers: item.goal_stats.num_users,
              numPosts: item.num_posts[0].count,
              isPrivate: item.is_private,
              isCompleted: item.is_completed,
              isArchived: item.is_archived,
              order: item.order,
            };
          });

          setGoalsData(formattedGoals);
          setLoading(false);
          setError(null);
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }
      };

      getUserGoals();
    } else {
      setGoals([]);
      setLoading(false);
      setError(null);
    }
  }, [userId]);

  const addUserGoal = async (title, userId, username) => {
    let newGoalId = null;
    let numUsers = 1;
    let slug = "";

    try {
      // check if this user already has this goal
      const isTitleExists = goals.openGoals.find((g) => g.Goaltitle === title);

      if (isTitleExists) {
        throw new Error("You are already working on this goal");
      }

      // Check if goal exists in global goals table
      let { data: goalData, error: goalError } = await supabase
        .from("goals")
        .select("id, title")
        .eq("title", title);

      if (goalError) {
        throw goalError;
      }

      // If global goal doesn't exist, create it
      if (goalData.length === 0) {
        const { data: insertData, error: insertError } = await supabase
          .from("goals")
          .insert({ title: title })
          .select()
          .single();

        if (insertError) {
          throw insertError;
        }
        newGoalId = insertData.id;
        slug = insertData.slug;
      } else {
        // if goal exists, get some data about the goal
        newGoalId = goalData[0].id;
        const { data: goalCounts, error } = await supabase
          .from("goal_stats")
          .select("*")
          .eq("goal_id", newGoalId);
        if (error) {
          throw error;
        }

        if (goalCounts.length > 0) {
          numUsers = goalCounts[0].num_users;
          slug = goalCounts[0].slug;
        }
      }

      // Create user_goal
      const { data, error } = await supabase
        .from("user_goal")
        .insert({ user_id: userId, goal_id: newGoalId })
        .select()
        .single();

      if (error) {
        throw error;
      }

      const newGoal = {
        id: data.id,
        createdAt: data.created_at,
        userId: data.user_id,
        username: username,
        goalId: data.goal_id,
        goalTitle: title,
        goalSlug: slug,
        numLikes: 1,
        numUsers: numUsers,
        numPosts: 0,
        isPrivate: data.is_private,
        isCompleted: data.is_completed,
      };

      setGoals((prevGoals) => {
        if (newGoal.isCompleted) {
          return {
            ...prevGoals,
            completedGoals: [...prevGoals.completedGoals, newGoal],
          };
        } else {
          return {
            ...prevGoals,
            openGoals: [...prevGoals.openGoals, newGoal],
          };
        }
      });
      setError(null);
    } catch (error) {
      if (!error.message) {
        setAlert("Something went wrong", "error");
      } else {
        setAlert(error.message, "error");
      }
    }
  };

  const deleteUserGoal = async (id) => {
    try {
      const { error } = await supabase.from("user_goal").delete().eq("id", id);
      if (error) {
        console.log(error);
        throw error;
      }
      setGoals((prevGoals) => ({
        ...prevGoals,
        openGoals: prevGoals.openGoals.filter((goal) => goal.id !== id),
        completedGoals: prevGoals.completedGoals.filter(
          (goal) => goal.id !== id
        ),
        archivedGoals: prevGoals.archivedGoals.filter((goal) => goal.id !== id),
      }));
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const archiveUserGoal = async (id) => {
    try {
      const { error } = await supabase
        .from("user_goal")
        .update({ is_archived: true })
        .eq("id", id);

      if (error) {
        throw error;
      }

      const goalToArchive = [...goals.openGoals, ...goals.completedGoals].find(
        (goal) => goal.id === id
      );

      setGoals((prevGoals) => ({
        ...prevGoals,
        openGoals: prevGoals.openGoals.filter((goal) => goal.id !== id),
        completedGoals: prevGoals.openGoals.filter((goal) => goal.id !== id),
        archivedGoals: [...prevGoals.archivedGoals, goalToArchive],
      }));

      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const reorderGoals = async (tempGoals, category) => {
    try {
      // Create array of {id, order} objects
      const updatedOrder = tempGoals.map((goal) => ({
        id: goal.id,
        order: goal.order,
      }));

      // Update order in user_goal table
      const { error } = await supabase.from("user_goal").upsert(updatedOrder);

      if (error) {
        throw error;
      }

      // Update order in state
      const updatedGoals = goals[category]
        .map((element) => {
          const orderUpdate = updatedOrder.find(
            (update) => update.id === element.id
          );
          if (orderUpdate) {
            return { ...element, order: orderUpdate.order };
          }
          return element;
        })
        .sort((a, b) => a.order - b.order);

      setGoals((prevGoals) => ({
        ...prevGoals,
        [category]: updatedGoals,
      }));

      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <UserGoalsContext.Provider
      value={{
        goals,
        loading,
        error,
        editing,
        userId,
        addUserGoal,
        deleteUserGoal,
        reorderGoals,
        archiveUserGoal,
      }}
    >
      {children}
    </UserGoalsContext.Provider>
  );
};
