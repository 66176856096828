import { Outlet } from "react-router-dom";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Container from "theme/Container";
import Footer from "shared/Footer";
import Navbar from "shared/menu/Navbar";

export default function Scaffolding() {
  const theme = useTheme();
  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
        }}
        elevation={0}
      >
        <Container paddingY={{ xs: 1, sm: 1.5 }}>
          <Navbar />
        </Container>
      </AppBar>
      <main>
        <Box height={{ xs: 58, sm: 66, md: 71 }} />
        <Box display="flex" flex="1 1 auto" overflow="hidden">
          <Box display="flex" flex="1 1 auto" overflow="hidden">
            <Box flex="1 1 auto" height="100%" overflow="auto">
              <Container>
                <Outlet />
              </Container>
              <Box height={{ xs: 58, sm: 66, md: 71 }} />
              <Divider />
              <Container paddingY={4}>
                <Footer />
              </Container>
            </Box>
          </Box>
        </Box>
      </main>
    </Box>
  );
}
