import { useState, useEffect } from "react";
import supabase from "services/supabaseClient";

export default function useUserGoalLike(userGoalId, user) {
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    async function fetchLiked() {
      if (user) {
        const { data, error } = await supabase
          .from("user_goal_likes")
          .select("id")
          .eq("user_goal_id", userGoalId)
          .eq("user_id", user.id);

        if (error) {
          setLiked(false);
        } else {
          setLiked(data.length > 0);
        }
      } else {
        setLiked(false);
      }
    }

    fetchLiked();
  }, [userGoalId, user]);

  async function toggleLike() {
    if (liked) {
      await supabase
        .from("user_goal_likes")
        .delete()
        .eq("user_goal_id", userGoalId)
        .eq("user_id", user.id);
    } else {
      await supabase
        .from("user_goal_likes")
        .insert({ user_goal_id: userGoalId, user_id: user.id });
    }

    setLiked(!liked);
  }

  return { liked, toggleLike };
}
