import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import supabase from "../services/supabaseClient";
import { useAuthContext } from "./useAuthContext";
import { postPropType } from "propTypes/postPropTypes";

export const usePosts = (userGoalId, userId) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, userProfile } = useAuthContext();

  usePosts.propTypes = {
    posts: PropTypes.arrayOf(postPropType),
  };

  function setPostsData(data) {
    PropTypes.checkPropTypes(
      { posts: PropTypes.arrayOf(postPropType) },
      { posts: data },
      "posts",
      "setPosts"
    );
    setPosts(data);
  }

  useEffect(() => {
    if (userGoalId) {
      // setup query
      let query = supabase
        .from("blog_posts")
        .select(
          "*, blog_comments(*), profiles(username), num_comments:blog_comments(count), num_likes:blog_likes(count), user_goal(goals(slug))"
        )
        .eq("user_goal_id", userGoalId)
        .order("created_at", { ascending: false });

      // filter out private posts if user is not the owner
      if (user && userId !== user.id) {
        query = query.neq("is_private", true);
      }

      const getBlogPosts = async () => {
        try {
          const { data: posts, error } = await query;

          if (error) {
            throw error;
          }

          const formattedPosts = posts.map((item) => {
            return {
              id: item.id,
              title: item.title,
              createdAt: item.created_at,
              userId: item.user_id,
              userGoalId: item.user_goal_id,
              isPrivate: item.is_private,
              username: item.profiles.username,
              content: item.content_json,
              numComments: item.num_comments[0].count,
              numLikes: item.num_likes[0].count + 1,
              goalSlug: item.user_goal.goals.slug,
            };
          });
          setPostsData(formattedPosts);
          setLoading(false);
          setError(null);
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }
      };

      getBlogPosts();
    } else {
      setPostsData(null);
      setLoading(false);
      setError(null);
    }
  }, [userGoalId, userId, user]);

  const addBlogPost = async (postData) => {
    try {
      const newPost = {
        title: postData.title,
        content_json: postData.contentJson,
        content_html: postData.contentHtml,
        user_id: postData.userId,
        user_goal_id: postData.userGoalId,
        is_private: postData.isPrivate,
      };

      const { data: insertData, error: insertError } = await supabase
        .from("blog_posts")
        .insert(newPost)
        .select()
        .single();

      if (insertError) {
        throw insertError;
      }

      const formattedPost = {
        id: insertData.id,
        title: insertData.title,
        createdAt: insertData.created_at,
        userId: insertData.user_id,
        userGoalId: insertData.user_goal_id,
        isPrivate: insertData.is_private,
        username: userProfile.username,
        content: insertData.content_json,
        numComments: 0,
        numLikes: 1,
        goalSlug: postData.goalSlug,
      };

      setPosts([...posts, formattedPost]);
    } catch (error) {
      setError(error.message);
    }
  };

  const deleteBlogPost = async (id) => {
    try {
      const { error } = await supabase.from("blog_posts").delete().eq("id", id);
      if (error) {
        throw error;
      }
      setPosts((prevPosts) =>
        prevPosts.filter((prevPost) => prevPost.id !== id)
      );
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    posts,
    loading,
    error,
    addBlogPost,
    deleteBlogPost,
  };
};
