import { useAuthContext } from "./useAuthContext";
import supabase from "../services/supabaseClient";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const logout = () => {
    supabase.auth
      .signOut()
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return { logout };
};
