import { useState } from "react";
import supabase from "../services/supabaseClient";

export const useAuth = () => {
  const [error, setError] = useState(null);

  const resetPassword = async (email) => {
    setError(null);

    const { error: resetError } = await supabase.auth.resetPasswordForEmail(
      email
    );
    if (resetError) {
      setError(resetError.message);
    }
  };

  return { error, resetPassword };
};
