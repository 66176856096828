import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const lastUpdated = "March 20, 2023";

export default function AboutContent() {
  return (
    <Box>
      <Typography variant="body1" paragraph>
        Ulpengo is a platform designed to help users create, manage, and share
        their bucket lists. We believe that life is an incredible journey filled
        with unique experiences, and our goal is to help you make the most of
        it.
      </Typography>
      <Typography variant="h5" paragraph>
        How It Works
      </Typography>
      <Typography variant="body1" paragraph>
        Simply declare your goals, track your journey through blog posts, and
        explore the aspirations of others on the platform. By sharing your
        experiences and connecting with like-minded individuals, you'll find
        inspiration, motivation, and encouragement to live life to the fullest.
      </Typography>
      <Typography variant="h5" paragraph>
        Our Vision
      </Typography>
      <Typography variant="body1" paragraph>
        Our vision is to create a supportive community where people from all
        walks of life can come together to pursue their dreams and ambitions. We
        believe that by making it easy for people to share their goals and
        progress, we can help foster a sense of camaraderie and inspire others
        to embark on their own adventures. Join us in creating a world where
        everyone has the opportunity to live a life filled with purpose,
        passion, and unforgettable experiences.
      </Typography>
    </Box>
  );
}
