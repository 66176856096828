import PropTypes from "prop-types";

export const profilePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  username: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  bio: PropTypes.string,
  location: PropTypes.string,
  birthday: PropTypes.string,
  website: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pronouns: PropTypes.string,
  isPrivate: PropTypes.bool,
});
