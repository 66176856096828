import { useParams } from "react-router-dom";
import Container from "theme/Container";
import { useSingleGoal } from "hooks/useSingleGoal";

// mui styles
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function ViewGoal() {
  const { goalId, goalSlug } = useParams();
  const { goal, loading, error, topUsers, recentPosts, newUsers, topTags } =
    useSingleGoal(goalId, goalSlug);

  return (
    <Container>
      <Box>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            {error && <p>{error}</p>}
            {loading && <h1>Loading...</h1>}
            {goal && goal.id && (
              <>
                <h2>{goal.title}</h2>
                {goal.description && <h3>{goal.description}</h3>}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {topUsers && topUsers.length > 0 && (
              <>
                <h3>Top Users</h3>
                <ul>
                  {topUsers.map((user, i) => (
                    <li key={i}>{user.username}</li>
                  ))}
                </ul>
              </>
            )}
            {newUsers && newUsers.length > 0 && (
              <>
                <h3>New Users</h3>
                <ul>
                  {newUsers.map((user, i) => (
                    <li key={i}>{user.username}</li>
                  ))}
                </ul>
              </>
            )}
            {recentPosts && recentPosts.length > 0 && (
              <>
                <h3>Recent Posts</h3>
                <ul>
                  {recentPosts.map((post, i) => (
                    <li key={i}>{post.title}</li>
                  ))}
                </ul>
              </>
            )}
            {topTags && topTags.length > 0 && (
              <>
                <h3>Top Tags</h3>
                <ul>
                  {topTags.map((tag, i) => (
                    <li key={i}>{tag.tag}</li>
                  ))}
                </ul>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
