import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import ScrollToTop from "./theme/scrollToTop";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import Home from "./pages/home/Home";
import People from "./pages/explore/People";
import UserGoal from "./pages/profile/usergoals/UserGoal";
import UserProfile from "./pages/profile/UserProfile";
import Scaffolding from "./theme/Scaffolding";
import Guidelines from "./pages/about/Guidelines";
import TermsOfService from "./pages/about/TermsOfService";
import EditProfile from "pages/profile/EditProfile";
import ViewPost from "pages/profile/usergoals/blog/posts/ViewPost";
import EditPost from "pages/profile/usergoals/blog/posts/EditPost";
import Goals from "pages/explore/Goals";
import ViewGoal from "pages/explore/goals/ViewGoal";
import Contact from "pages/about/Contact";
import ForgotPassword from "pages/auth/ForgotPassword";
import Welcome from "pages/home/Welcome";
import About from "pages/about/About";

export default function Router() {
  const { user, authIsReady } = useAuthContext();
  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route element={<Scaffolding />}>
              <Route
                path="/home"
                element={user ? <Home /> : <Navigate to="/login" />}
              />
              <Route path="/about/guidelines" element={<Guidelines />} />
              <Route
                path="/about/terms-of-service"
                element={<TermsOfService />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/about/contact" element={<Contact />} />
              <Route
                path="/account/profile/edit"
                element={user ? <EditProfile /> : <Navigate to="/login" />}
              />
              <Route path="/explore/people" element={<People />} />
              <Route path="/explore/goals" element={<Goals />} />
              <Route
                path="/explore/goals/:goalId/:goalSlug"
                element={<ViewGoal />}
              />
              <Route path="/profiles/:username" element={<UserProfile />} />
              <Route
                path="/profiles/:username/:userGoalId/:goalSlug"
                element={<UserGoal />}
              />
              <Route
                path="/profiles/:username/:userGoalId/:goalSlug/:postId"
                element={<ViewPost />}
              />
              <Route
                path="/profiles/:username/:userGoalId/:goalSlug/:postId/edit"
                element={user ? <EditPost /> : <Navigate to="/login" />}
              />
              <Route path="*" element={user ? <Home /> : <Welcome />} />
              <Route
                path="/login"
                element={user ? <Navigate to="/" /> : <Login />}
              />
              <Route
                path="/signup"
                element={user ? <Navigate to="/" /> : <Signup />}
              />
              <Route
                path="/forgot-password"
                element={user ? <Navigate to="/" /> : <ForgotPassword />}
              />
              <Route
                path="/forgot-password/reset"
                element={
                  user ? <Navigate to="/" /> : <ForgotPassword reset={true} />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}
