import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuthContext } from "../../hooks/useAuthContext";
import supabase from "../../services/supabaseClient";

// MUI styles
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function AddUserGoal({ onAdd, userId }) {
  AddUserGoal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
  };
  const [newGoalTitle, setNewGoalTitle] = useState("");
  const { user, userProfile } = useAuthContext();
  const [goalSuggestions, setGoalSuggestions] = useState([""]);

  useEffect(() => {
    function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }

    const getGoalIdeas = async () => {
      try {
        const { data: latestGoals, error: latestGoalsError } = await supabase
          .from("goal_stats")
          .select("title")
          .is("approved", true)
          .order("num_users", { ascending: false })
          .limit(10);

        if (latestGoalsError) {
          throw latestGoalsError;
        }

        const { data: userGoals, error } = await supabase
          .from("user_goal")
          .select("goals(title)")
          .eq("user_id", user.id);

        if (error) {
          throw error;
        }

        const latestGoalTitles = latestGoals.map((goal) => goal.title);
        const userGoalTitles = userGoals.map(
          (usergoal) => usergoal.goals.title
        );
        const filteredGoals = latestGoalTitles.filter(
          (title) => !userGoalTitles.some((goalTitle) => goalTitle === title)
        );
        shuffleArray(filteredGoals);
        setGoalSuggestions(filteredGoals);
      } catch (error) {
        console.log(error);
      }
    };
    getGoalIdeas();
  }, [user.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAdd(newGoalTitle);
  };

  const handleAdd = async (item) => {
    if (userId !== user.id) {
      return;
    }

    try {
      await onAdd(item, userId, userProfile.username);
    } catch (error) {
      console.log(error);
    } finally {
      setNewGoalTitle("");
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ width: "100%" }}
    >
      {goalSuggestions && (
        <>
          <Stack direction="row" spacing={2}>
            <TextField
              value={newGoalTitle}
              id="new-goal-title"
              onChange={(e) => setNewGoalTitle(e.target.value)}
              required
              fullWidth
              size="medium"
              label="What would you like to do?"
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}
            >
              Add Goal
            </Button>
          </Stack>
          <Box>
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              mt={2}
            >
              {goalSuggestions.length > 1 &&
                goalSuggestions.map((item) => (
                  <Chip
                    key={item}
                    onClick={() => handleAdd(item)}
                    onDelete={() => handleAdd(item)}
                    label={item}
                    clickable
                    sx={{
                      margin: 0.5,
                    }}
                    size="small"
                    deleteIcon={<AddCircleIcon />}
                  />
                ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
