import React, { useRef } from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useAuthContext } from "hooks/useAuthContext";
import useAlert from "hooks/useAlert";

// mui styles
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Container from "theme/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ContactForm() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const form = useRef();
  const { setAlert } = useAlert();
  const { userProfile } = useAuthContext();

  const emailKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const emailService = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailTemplate = "template_pcvnumj";

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email || !message || !name) {
      setAlert("Please fill out all fields.", "error");
      return;
    }

    let user = "Anonymous";
    if (userProfile) {
      user = userProfile.username;
    }

    const templateParams = {
      from_email: email,
      from_name: name,
      message: message,
      name: name,
      user: user,
    };

    emailjs.send(emailService, emailTemplate, templateParams, emailKey).then(
      (result) => {
        setAlert("Message sent successfully!", "success");
        setEmail("");
        setMessage("");
        setName("");
      },
      (error) => {
        setAlert("An error has occured - try again.", "error");
      }
    );
  };

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom fontWeight={700}>
            Get in touch
          </Typography>
          <Typography variant={"subtitle2"} color={"text.secondary"}>
            We'll get back to you as soon as possible.
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            component="form"
            ref={form}
            onSubmit={sendEmail}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              label="Message"
              variant="outlined"
              name={"message"}
              multiline
              fullWidth
              required
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Grid container justifyContent="flex-end">
              <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Send
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
