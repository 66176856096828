import { useState } from "react";

// for MUI
import PropTypes from "prop-types";
import moment from "moment/moment";

// MUI styles
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function CompleteGoal({
  open,
  handleClose,
  title,
  completeUserGoal,
}) {
  CompleteGoal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    completeUserGoal: PropTypes.func.isRequired,
  };

  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const [dateComplete, setDateComplete] = useState(moment());
  const [hover, setHover] = useState(5);

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: "Really not worth it.",
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: "Not worth it",
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: "It was okay",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: "Worth it",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: "Totally worth it!",
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updates = {
      rating,
      review,
      completedAt: dateComplete.toISOString(),
    };

    await completeUserGoal(updates);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="md"
      sx={{ m: 2 }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="baseline">
          <Box flexGrow={1}>
            Awesome!
            <Typography variant="subtitle1" gutterBottom>
              You completed {title}.
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} component="form" noValidate autoComplete="off">
          <TextField
            id="how-went"
            label="How did it go?"
            autoComplete="off"
            multiline
            rows={4}
            fullWidth
            value={review}
            onChange={(e) => {
              setReview(e.target.value);
            }}
          />

          <Box sx={{ display: "flex", justifyContent: "right" }}>
            {rating && (
              <Box sx={{ mr: 2 }}>
                {customIcons[hover !== -1 ? hover : rating].label}
              </Box>
            )}
            <StyledRating
              name="highlight-selected-only"
              defaultValue={rating}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value].label}
              highlightSelectedOnly
              value={rating}
              onChange={(e) => {
                setRating(parseInt(e.target.value, 10));
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
            />
          </Box>
          <Box sx={{ width: 300, pt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="When did you complete it?"
                inputFormat="MM/DD/YYYY"
                value={dateComplete}
                onChange={(newValue) => {
                  setDateComplete(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" sx={{ width: 200 }}>
          Mark Complete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
