import PropTypes from "prop-types";

export const userGoalPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  goalId: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  username: PropTypes.string,
  goalTitle: PropTypes.string.isRequired,
  goalSlug: PropTypes.string.isRequired,
  numUsers: PropTypes.number.isRequired,
  numLikes: PropTypes.number.isRequired,
  numPosts: PropTypes.number.isRequired,
  goalDescription: PropTypes.string,
  isOwner: PropTypes.bool,
  order: PropTypes.number,
  isArchived: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
});
