const menuContent = [
  {
    title: "My Goals",
    route: "/dashboard",
  },
  {
    title: "Explore",
    route: "/explore/goals",
    permissions: "guest",
  },
  {
    title: "Community",
    route: "/explore/people",
    permissions: "guest",
  },
  {
    title: "Account",
    route: "/account",
    special: "avatar",
    subItems: [
      {
        title: "Edit Profile",
        route: "/account/profile/edit",
      },
      {
        title: "Logout",
        special: "logout",
      },
    ],
  },
  {
    title: "Login",
    route: "/login",
    permissions: "guest",
    special: "button",
  },
  {
    title: "Sign up",
    route: "/signup",
    permissions: "guest",
    special: "button",
  },
];

export default menuContent;
