import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { postPropType } from "propTypes/postPropTypes";
import supabase from "../services/supabaseClient";

export const useSinglePost = (username, userGoalId, goalSlug, postId) => {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useSinglePost.propTypes = {
    post: postPropType,
  };

  function setPostData(data) {
    PropTypes.checkPropTypes(
      { posts: postPropType },
      { posts: data },
      "post",
      "setPost"
    );
    console.log(data);
    setPost(data);
  }

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const { data, error } = await supabase
        .from("blog_posts")
        .select(
          "*, user_goal(goals(slug)), profiles(username), blog_comments(*, profiles(username)), num_comments:blog_comments(count), num_likes:blog_likes(count)"
        )
        .eq("id", postId)
        .single();

      if (error) {
        setError("Post not found.");
        setLoading(false);
      } else if (data.profiles.username !== username) {
        setError("Something went wrong. Try again.");
        setLoading(false);
      } else if (data.user_goal.goals.slug !== goalSlug) {
        setError("Something went wrong. Try again.");
        setLoading(false);
      } else {
        const formattedPost = {
          id: data.id,
          title: data.title,
          createdAt: data.created_at,
          userId: data.user_id,
          userGoalId: data.user_goal_id,
          isPrivate: data.is_private,
          username: data.profiles.username,
          content: data.content_json,
          numComments: data.num_comments[0].count,
          numLikes: data.num_likes[0].count + 1,
          goalSlug: data.user_goal.goals.slug,
          comments: data.blog_comments.map((comment) => {
            return {
              id: comment.id,
              postId: comment.blog_post_id,
              userId: comment.user_id,
              username: comment.profiles.username,
              content: comment.content_json,
              createdAt: comment.created_at,
            };
          }),
        };

        setPostData(formattedPost);
        setLoading(false);
      }
    };
    fetchPost();
  }, [userGoalId, goalSlug, postId, username]);

  const addComment = async (comment, username) => {
    const { data, error } = await supabase
      .from("blog_comments")
      .insert(comment)
      .select()
      .single();

    const formattedComment = {
      id: data.id,
      postId: data.blog_post_id,
      userId: data.user_id,
      username: username,
      content: data.content_json,
      createdAt: data.created_at,
    };

    if (error) {
      setError(error.message);
    } else {
      const formattedPost = {
        ...post,
        numComments: post.numComments + 1,
        comments: [...post.comments, formattedComment],
      };
      setPostData(formattedPost);
    }
  };

  const deleteComment = async (id) => {
    try {
      const { error } = await supabase
        .from("blog_comments")
        .delete()
        .eq("id", id);
      if (error) {
        throw error;
      }
      setPost((prevPost) => ({
        ...prevPost,
        comments: prevPost.comments.filter((comment) => comment.id !== id),
      }));

      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const updatePost = async (postData) => {
    const postUpdates = {
      title: postData.title,
      is_private: postData.privatePost,
      content_json: postData.newContentJSON,
      content_html: postData.newContentHTML,
    };

    const { data, error } = await supabase
      .from("blog_posts")
      .update(postUpdates)
      .eq("id", postId)
      .select()
      .single();

    if (error) {
      throw new Error(error.message);
    } else {
      const formattedPost = {
        ...post,
        title: data.title,
        isPrivate: data.is_private,
        content: data.content_json,
      };

      setPostData(formattedPost);
      setError(null);
    }
  };

  return {
    post,
    loading,
    error,
    addComment,
    deleteComment,
    updatePost,
  };
};
