import { useState, useEffect } from "react";
import { profilePropType } from "propTypes/profilePropTypes";
import supabase from "../services/supabaseClient";
import PropTypes from "prop-types";

export const useSingleProfile = (username) => {
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  function setProfileData(data) {
    PropTypes.checkPropTypes(
      { posts: profilePropType },
      { posts: data },
      "profile",
      "setProfile"
    );
    setProfile(data);
  }

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from("profiles")
        .select("*")
        .eq("username", username)
        .single();

      if (fetchError) {
        setError("Profile not found");
        setLoading(false);
      } else {
        const formattedProfile = {
          id: data.id,
          updatedAt: data.updated_at,
          username: data.username,
          avatarUrl: data.avatar_url,
          bio: data.bio,
          location: data.location,
          birthday: data.birthday,
          website: data.website,
          firstName: data.first_name,
          lastName: data.last_name,
          pronouns: data.pronouns,
          isPrivate: data.is_private,
        };

        setProfileData(formattedProfile);
        setLoading(false);
        setError(null);
      }
    };
    fetchPost();
  }, [username]);

  const updateProfile = async (profileData) => {
    if (profile.username !== profileData.username) {
      const { data: profiles } = await supabase
        .from("profiles")
        .select("username")
        .eq("username", profileData.username)
        .single();

      if (profiles) {
        throw new Error("That username is already taken.");
      }
    }

    const profileUpdates = {
      username: profileData.username,
      avatar_url: profileData.avatarUrl,
      bio: profileData.bio,
      location: profileData.location,
      birthday: profileData.birthday,
      website: profileData.website,
      first_name: profileData.firstName,
      last_name: profileData.lastName,
      pronouns: profileData.pronouns,
      updated_at: new Date(),
    };

    const { data, error: profilesError } = await supabase
      .from("profiles")
      .update(profileUpdates)
      .eq("id", profileData.id)
      .select()
      .single();

    if (profilesError) {
      throw new Error("Profile not found");
    } else {
      const formattedProfile = {
        id: data.id,
        updatedAt: data.updated_at,
        username: data.username,
        avatarUrl: data.avatar_url,
        bio: data.bio,
        location: data.location,
        birthday: data.birthday,
        website: data.website,
        firstName: data.first_name,
        lastName: data.last_name,
        pronouns: data.pronouns,
        isPrivate: data.is_private,
      };

      setProfileData(formattedProfile);
      setLoading(false);
    }
  };

  return {
    profile,
    loading,
    error,
    updateProfile,
  };
};
