import React, { useState } from "react";
import propTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import PostViewer from "../components/PostViewer";
import PostLike from "../components/PostLike";
import { postPropType } from "propTypes/postPropTypes";
import moment from "moment";

//mui imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import Paper from "@mui/material/Paper";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

export default function Post({
  post,
  isOwner,
  setPostIdToDelete,
  handleOpenDeleteConfirm,
}) {
  Post.propTypes = {
    post: postPropType.isRequired,
    isOwner: propTypes.bool.isRequired,
    setPostIdToDelete: propTypes.func.isRequired,
    handleOpenDeleteConfirm: propTypes.func.isRequired,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [tempLikes, setTempLikes] = useState(post.numLikes);

  const titleHeader = (
    <>
      {post.title}
      {post.is_private && (
        <Tooltip title="Only viewable by you" enterDelay={500}>
          <LockIcon
            sx={{
              fontSize: "inherit",
              verticalAlign: "text-top",
              marginLeft: 1,
            }}
          />
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      <Paper elevation={3}>
        <Card variant="outlined">
          <CardHeader
            action={
              isOwner && (
                <IconButton
                  aria-label="edit"
                  id="edit-menu"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
            title={titleHeader}
            subheader={moment(post.createdAt).format(
              "dddd, MMMM D, YYYY [at] h:mm a"
            )}
          />
          <CardContent>
            {post.content && (
              <PostViewer content={post.content} contentKey={post.id} />
            )}
          </CardContent>
          <CardActions>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  size="small"
                  component={RouterLink}
                  to={`/profiles/${post.username}/${post.userGoalId}/${post.goalSlug}/${post.id}`}
                >
                  {`${
                    post.numComments === 0 ? "No" : post.numComments
                  } Comment${post.numComments === 1 ? "" : "s"}`}
                </Button>
              </Grid>
              <Grid item>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {`${tempLikes === 0 ? "No" : tempLikes} Cheer${
                    tempLikes === 1 ? "" : "s"
                  }`}

                  <PostLike
                    postId={post.id}
                    userId={post.userId}
                    startingLikes={post.numLikes}
                    setTempLikes={setTempLikes}
                  />
                </Typography>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Paper>
      <Menu
        id="edit-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`/profiles/${post.username}/${post.userGoalId}/${post.goalSlug}/${post.id}/edit`}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPostIdToDelete(post.id);
            handleOpenDeleteConfirm();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
