import { AuthContextProvider } from "./context/AuthContext";
import AlertSnack from "shared/AlertSnack";
import Router from "Router";

// mui theme
import Page from "./theme/Page";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function App() {
  if (process.env.REACT_APP_OFFLINE === "true") {
    return process.env.REACT_APP_OFFLINE_MSG;
  }

  return (
    <AuthContextProvider>
      <Page>
        <Router />
        <AlertSnack />
      </Page>
    </AuthContextProvider>
  );
}

export default App;

// /home
// /explore
// /explore/people
// /profiles/{username}
// /profiles/username}/{usergoalID}/{goal-name}
// /profiles/username}/{usergoal}/posts/{postID}/{post-slug}
// /goals/{goalID}/{goal-name}
