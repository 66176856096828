import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import PostViewer from "../components/PostViewer";
import Comment from "../comments/Comment";
import AddComment from "../comments/AddComment";
import { useSinglePost } from "hooks/useSinglePost";
import { useAuthContext } from "hooks/useAuthContext";

// mui styles
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

// route path: /profile/:username/:userGoalId/:goalSlug/:postId
// example: /profile/username/1/first-goal/1

export default function ViewPost() {
  const { username } = useParams();
  const { userGoalId } = useParams();
  const { goalSlug } = useParams();
  const { postId } = useParams();
  const { user, userProfile } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.username === username) {
        setIsOwner(true);
      }
    }
  }, [userProfile, username]);

  const [commentIdToDelete, setCommentIdToDelete] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const { post, error, addComment, deleteComment } = useSinglePost(
    username,
    userGoalId,
    goalSlug,
    postId
  );

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDelete = () => {
    deleteComment(commentIdToDelete);
    handleCloseDeleteConfirm();
  };

  const handleEdit = () => {
    const currentPath = location.pathname;
    const newPath = currentPath + "/edit";
    navigate(newPath);
  };

  return (
    <div>
      <h1>View Post</h1>
      <Button
        variant="text"
        component={RouterLink}
        to={`/profiles/${username}/${userGoalId}/${goalSlug}`}
      >
        {`Back to user goal`}
      </Button>
      {error && <p>{error}</p>}
      {post && (
        <div>
          <div>
            <h2>{post.title}</h2>
            {isOwner && (
              <Button onClick={handleEdit} autoFocus>
                Edit
              </Button>
            )}
            {post.content && (
              <PostViewer content={post.content} contentKey={post.id} />
            )}
          </div>
          <Divider sx={{ paddingTop: 3 }} />
          <h3>Comments</h3>
          {post.comments && (
            <Grid container spacing={4}>
              {post.comments.map((comment, i) => (
                <Grid xs={12} x={8} item key={i}>
                  <Comment
                    key={comment.id}
                    content={comment.content}
                    createdAt={comment.createdAt}
                    username={comment.username}
                  />
                  {comment.userId === user.id && (
                    <Button
                      variant="text"
                      onClick={() => {
                        setCommentIdToDelete(comment.id);
                        handleOpenDeleteConfirm();
                      }}
                    >{`Delete`}</Button>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {!post.comments && <p>No comments yet</p>}
          <AddComment postId={post.id} onAddComment={addComment} />
        </div>
      )}
      <Dialog
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this comment?"}
        </DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleCloseDeleteConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
