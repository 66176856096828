import React from "react";
import PropTypes from "prop-types";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";

export default function PostViewer({ content, contentKey }) {
  PostViewer.propTypes = {
    content: PropTypes.any.isRequired,
    contentKey: PropTypes.any,
  };

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: (typeof content === "object" && content) || " ",
    editable: false,
    // editorProps: {
    //   attributes: {
    //     class: "viewer",
    //   },
    // },
  });

  if (!contentKey) {
    return <EditorContent editor={editor} />;
  }
  return <EditorContent editor={editor} key={contentKey} />;
}
