import { useEffect, useState } from "react";
import AddPost from "./posts/AddPost";
import { usePosts } from "../../../../hooks/usePosts";
import { useAuthContext } from "hooks/useAuthContext";
import Post from "./posts/Post";

//mui imports
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

export default function Blog({ userGoalId, userId, userName, slug }) {
  const { posts, loading, error, addBlogPost, deleteBlogPost } = usePosts(
    userGoalId,
    userId
  );
  const [isOwner, setIsOwner] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      if (user.id === userId) {
        setIsOwner(true);
      }
    }
  }, [user, userId]);

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDelete = () => {
    deleteBlogPost(postIdToDelete);
    handleCloseDeleteConfirm();
  };

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Stack spacing={2}>
          {!error &&
            posts &&
            posts.map((post) => {
              return (
                <Post
                  post={post}
                  key={post.id}
                  handleOpenDeleteConfirm={handleOpenDeleteConfirm}
                  setPostIdToDelete={setPostIdToDelete}
                  isOwner={isOwner}
                />
              );
            })}
          {!error && posts && posts.length === 0 && <div>No entries yet</div>}
        </Stack>
      )}
      <Dialog
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this post?"}
        </DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleCloseDeleteConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isOwner && (
        <AddPost
          onAdd={addBlogPost}
          userGoalId={userGoalId}
          userId={userId}
          slug={slug}
        />
      )}
    </div>
  );
}
