import { useEffect, useState } from "react";
import supabase from "services/supabaseClient";

// mui styles
import Avatar from "@mui/material/Avatar";

export default function DisplayAvatar({ url, size }) {
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    const downloadImage = async (path) => {
      try {
        const { data, error } = await supabase.storage
          .from("avatars")
          .download(path);
        if (error) {
          throw error;
        }
        const url = URL.createObjectURL(data);
        setAvatarUrl(url);
      } catch (error) {
        console.log("Error downloading image");
      }
    };
    if (url) downloadImage(url);
  }, [url]);

  return (
    <>
      <Avatar
        src={avatarUrl ? avatarUrl : null}
        variant={"circular"}
        sx={{
          width: size,
          height: size,
        }}
      />
    </>
  );
}
