import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useAlert from "hooks/useAlert";

const AlertPopup = () => {
  const { text, type, open, duration, setOpen } = useAlert();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <MuiAlert
        onClose={handleClose}
        severity={type}
        elevation={6}
        variant="filled"
      >
        {text}
      </MuiAlert>
    </Snackbar>
  );
};

export default AlertPopup;
