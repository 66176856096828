import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "theme/Container";
import { useGoals } from "hooks/useGoals";

// MUI styles
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";

export default function Goals() {
  const theme = useTheme();
  const perPage = 12;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(perPage - 1);
  const { goals, loading, error, numPages } = useGoals(perPage, start, end);

  const handleChange = (event, value) => {
    setStart((value - 1) * (perPage - 1));
    setEnd(value * (perPage - 1));
    console.log(start, end);
  };

  function CustomGoalCard(props) {
    const { title, description, numUsers, id, slug } = props;
    return (
      <Grid key={id} item xs={12} sm={4}>
        <Card
          sx={{
            p: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            alignitems: "left",
            width: 1,
            height: 1,
            background: "transparent",
            backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 90%, ${theme.palette.primary.main} 0%)`,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"left"}
            alignItems={"center"}
            marginTop={2}
          >
            <Typography fontWeight={700}>{title}</Typography>
          </Box>
          <Typography color={"text.secondary"}>{description}</Typography>

          <Box flexGrow={1} />
          <Stack spacing={2} marginTop={4} width={1} alignItems={"center"}>
            <Typography color={"text.secondary"} variant={"subtitle2"}>
              {numUsers} people
            </Typography>
            <Button
              variant={"outlined"}
              color={"primary"}
              component={RouterLink}
              to={`/explore/goals/${id}/${slug}`}
              fullWidth
            >
              View goal
            </Button>
          </Stack>
        </Card>
      </Grid>
    );
  }

  function CustomGoalCardSkeleton(props) {
    const { id } = props;
    return (
      <Grid key={id} item xs={12} sm={4}>
        <Card
          sx={{
            p: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            alignitems: "left",
            width: 1,
            height: 1,
            background: "transparent",
            backgroundImage: `linear-gradient(0deg, ${theme.palette.background.paper} 90%, ${theme.palette.primary.main} 0%)`,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"left"}
            alignItems={"center"}
            marginTop={2}
          >
            <Skeleton variant="rectangular" height={20} width="100%" />
          </Box>
          <Skeleton width="100%" />
          <Skeleton width="60%" />

          <Box flexGrow={1} />
          <Stack spacing={2} marginTop={4} width={1} alignItems={"center"}>
            <Skeleton width="25%" />
            <Skeleton variant="rectangular" height={40} width="100%" />
          </Stack>
        </Card>
      </Grid>
    );
  }

  const skeletonCards = Array.from({ length: perPage }, (_, index) => (
    <CustomGoalCardSkeleton key={index} />
  ));

  return (
    <Container>
      <h1>Goals</h1>
      <Grid container spacing={4}>
        {error && <p>{error}</p>}

        {!loading &&
          goals &&
          goals.map((goal, i) => <CustomGoalCard key={i} {...goal} />)}
        {loading && skeletonCards}
      </Grid>
      <Stack spacing={2} sx={{ mt: 10 }}>
        {numPages && (
          <Pagination
            count={numPages}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        )}
      </Stack>
    </Container>
  );
}
