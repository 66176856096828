import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import UserGoals from "../../shared/userGoals/UserGoals";

// mui styles
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

export default function Home() {
  const { user, userProfile } = useAuthContext();
  const [editing, setEditing] = useState(false);

  return (
    <>
      {userProfile && (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <h1>Welcome {userProfile.username}!</h1>
            <IconButton
              color={editing ? "primary" : ""}
              aria-label="edit goals"
              onClick={() => setEditing(!editing)}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <UserGoals userId={user.id} editing={editing} />
        </>
      )}
      {!userProfile && <h1>Welcome!</h1>}
    </>
  );
}
