import PropTypes from "prop-types";
import { useState } from "react";
import List from "@mui/material/List";
import UserGoalsListItem from "./UserGoalsListItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useUserGoalsContext } from "hooks/useUserGoalsContext";

// MUI styles
import Divider from "@mui/material/Divider";

UserGoalsList.propTypes = {
  category: PropTypes.string,
};

export default function UserGoalsList({ category }) {
  const { goals, reorderGoals } = useUserGoalsContext();
  const [listGoals, setListGoals] = useState(goals[category]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newGoals = Array.from(listGoals);
    const [removed] = newGoals.splice(source.index, 1);
    newGoals.splice(destination.index, 0, removed);

    const updatedGoals = newGoals.map((goal, index) => {
      return {
        ...goal,
        order: index,
      };
    });

    setListGoals(updatedGoals);
    reorderGoals(updatedGoals, category);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="goals">
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ width: "100%" }}
          >
            {listGoals &&
              listGoals.map((goal, index) => (
                <div key={goal.id}>
                  <UserGoalsListItem
                    goal={goal}
                    index={index}
                    category={category}
                  />
                  <Divider component="li" />
                </div>
              ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
