import PropTypes from "prop-types";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

// MUI styles
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import CommentViewer from "../components/CommentViewer";

export default function Comment({ content, createdAt, username }) {
  Comment.propTypes = {
    content: PropTypes.object,
    createdAt: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  };
  if (!content) return null;
  return (
    <>
      <CommentViewer content={content} />
      <Typography variant={"caption"} color={"text.secondary"}>
        <>
          {"by "}
          <Link component={RouterLink} to={`/profiles/${username}`}>
            {username}
          </Link>
          {", "}
          <Tooltip
            enterDelay={500}
            title={moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          >
            <span> {moment(createdAt).fromNow()}</span>
          </Tooltip>
        </>
      </Typography>
    </>
  );
}
