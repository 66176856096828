import PropTypes from "prop-types";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import useUserGoalLike from "hooks/useUserGoalLike";
import { userGoalPropType } from "propTypes/userGoalPropTypes";
import { Draggable } from "react-beautiful-dnd";
import { useUserGoalsContext } from "hooks/useUserGoalsContext";

// MUI styles
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Alert from "@mui/material/Alert";
import ArchiveIcon from "@mui/icons-material/Archive";

UserGoalsListItem.propTypes = {
  goal: userGoalPropType,
  index: PropTypes.number,
};

export default function UserGoalsListItem({ goal, index, category }) {
  const [tempLikes, setTempLikes] = useState(goal.numLikes);
  const { user } = useAuthContext();
  const { liked, toggleLike } = useUserGoalLike(goal.id, user);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false);
  const { editing, deleteUserGoal, archiveUserGoal } = useUserGoalsContext();

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDelete = () => {
    deleteUserGoal(goal.id);
    handleCloseDeleteConfirm();
  };

  const handleCloseArchiveConfirm = () => {
    setOpenArchiveConfirm(false);
  };

  const handleOpenArchiveConfirm = () => {
    setOpenArchiveConfirm(true);
  };

  const handleArchive = () => {
    archiveUserGoal(goal.id);
    handleCloseArchiveConfirm();
  };

  const handleLike = () => {
    toggleLike();
    if (liked) {
      setTempLikes(tempLikes - 1);
    } else {
      setTempLikes(tempLikes + 1);
    }
  };

  return (
    <>
      <Draggable
        isDragDissabled={!editing}
        draggableId={goal.id.toString()}
        index={index}
      >
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            alignItems="flex-start"
            secondaryAction=<Typography
              sx={{ display: "inline" }}
              component="span"
              variant="caption"
              color="text.primary"
            >
              {`${goal.numUsers} people`}
            </Typography>
          >
            <div {...provided.dragHandleProps}>
              {editing && (
                <ListItemIcon>
                  <DragHandleIcon />
                </ListItemIcon>
              )}
            </div>
            <ListItemText
              primary={goal.goalTitle}
              secondary={
                <>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${goal.numPosts === 0 ? "No" : goal.numPosts} post${
                      goal.numPosts === 1 ? "" : "s"
                    }`}
                    {` - `}
                    {`${tempLikes === 0 ? "No" : tempLikes} cheer${
                      tempLikes === 1 ? "" : "s"
                    }`}
                    {user && (
                      <Button variant="text" onClick={handleLike}>
                        {liked ? "Unlike" : "Like"}
                      </Button>
                    )}
                    {user && (
                      <Button
                        variant="text"
                        component={RouterLink}
                        to={`/profiles/${goal.username}/${goal.id}/${goal.goalSlug}`}
                      >
                        View
                      </Button>
                    )}
                    {editing && category === "archivedGoals" && (
                      <IconButton
                        aria-label="delete"
                        onClick={handleOpenDeleteConfirm}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {editing && category !== "archivedGoals" && (
                      <IconButton
                        aria-label="archive"
                        onClick={handleOpenArchiveConfirm}
                      >
                        <ArchiveIcon />
                      </IconButton>
                    )}
                  </Typography>
                </>
              }
            />
          </ListItem>
        )}
      </Draggable>
      <Dialog
        open={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this post?"}
        </DialogTitle>
        <Alert severity="error">
          This will permanently delete all posts, comments, and cheers related
          to this goal.
        </Alert>
        <DialogActions>
          <Button color="secondary" onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleCloseDeleteConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openArchiveConfirm}
        onClose={handleCloseArchiveConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to archive this post?"}
        </DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={handleArchive}>
            Archive
          </Button>
          <Button onClick={handleCloseArchiveConfirm} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
