import { useState, useEffect } from "react";

// mui styles
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "theme/Container";
import DisplayAvatar from "shared/DisplayAvatar";

const UserProfileBio = ({ profile }) => {
  const [websiteUrl, setWebsiteUrl] = useState(null);

  useEffect(() => {
    if (profile.website) {
      let websiteUrl = profile.website;
      if (
        websiteUrl &&
        !websiteUrl.startsWith("http://") &&
        !websiteUrl.startsWith("https://")
      ) {
        websiteUrl = "http://" + websiteUrl;
      }
      setWebsiteUrl(websiteUrl);
    }
  }, [profile.website]);

  return (
    <Container sx={{ paddingY: 1 }}>
      <Card
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: 1,
          height: 1,
        }}
      >
        <Stack spacing={2} width="auto" alignItems={"center"}>
          <DisplayAvatar url={profile.avatarUrl} size={{ xs: 150, lg: 200 }} />
        </Stack>
        <Box marginLeft={0} marginTop={4}>
          <Box display={"flex"} alignItems={"center"} marginBottom={1}>
            <Typography fontWeight={700} variant={"h5"}>
              {profile.username}
            </Typography>
            <Typography
              color={"text.secondary"}
              marginX={1}
              style={{ display: profile.pronouns ? "block" : "none" }}
            >
              {profile.pronouns}
            </Typography>
          </Box>

          <Stack
            direction="column"
            spacing={1}
            style={{
              display: profile.firstName || profile.lastName ? "block" : "none",
            }}
          >
            <Typography>{`${profile.firstName} ${profile.lastName}`}</Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={1}
            marginY={2}
            alignItems={"flex-start"}
          >
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={profile.location ? "flex" : "none"}
            >
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={18}
                height={18}
                color={"primary.dark"}
                marginRight={1}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </Box>
              <Typography color={"primary"} variant={"subtitle2"}>
                {profile.location}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                width={18}
                height={18}
                color={"primary.dark"}
                marginRight={1}
                display={profile.website ? "block" : "none"}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </Box>
              <Link
                color={"primary"}
                variant={"subtitle2"}
                href={websiteUrl}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                {profile.website}
              </Link>
            </Box>
          </Stack>
          <Typography variant={"subtitle2"} component={"p"}>
            {profile.bio}
          </Typography>
        </Box>
      </Card>
    </Container>
  );
};

export default UserProfileBio;
