import React, { useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import "./Welcome.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const goals = [
  "travel the world",
  "learn a new language",
  "skydive",
  "run a marathon",
  "write a book",
  "see the Northern Lights",
  "attend a major sporting event",
  "learn to play a musical instrument",
  "scuba dive at the Great Barrier Reef",
  "visit all seven continents",
  "go on a hot air balloon ride",
  "climb a mountain",
  "hike the Grand Canyon",
  "swim with dolphins",
  "attend a music festival",
  "learn to cook a new cuisine",
  "go on a safari",
  "cruise the Galápagos Islands",
  "volunteer for a cause",
  "see the Great Wall of China",
  "visit the Pyramids of Giza",
  "witness a solar eclipse",
  "see the cherry blossoms in Japan",
  "visit all 50 states in the US",
  "ride in a gondola in Venice",
  "attend the Olympics",
  "go on a road trip",
  "see a Broadway show",
  "experience zero gravity",
  "visit the Amazon rainforest",
  "trek to Machu Picchu",
  "explore Antarctica",
  "go whale watching",
  "visit the Vatican",
  "witness the wildebeest migration in Africa",
  "ride a camel in the Sahara Desert",
  "go island hopping in Greece",
  "attend Mardi Gras in New Orleans",
  "take a cooking class in Italy",
  "complete a triathlon",
  "drive the Pacific Coast Highway",
  "learn a new skill or hobby",
  "visit an active volcano",
  "explore the Galápagos Islands",
  "attend a film premiere or a red carpet event",
  "drive along Route 66",
  "float in the Dead Sea",
  "see the Sistine Chapel",
  "go dog sledding in Alaska",
  "walk the Camino de Santiago",
];

export default function Welcome() {
  const inputRef = useRef();
  const indexRef = useRef(0);
  const typingInterval = useRef(null);
  const deletingInterval = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const typeGoal = (callback) => {
      let goalIndex = 0;
      typingInterval.current = setInterval(() => {
        inputRef.current.textContent += goals[indexRef.current][goalIndex];
        goalIndex++;

        if (goalIndex >= goals[indexRef.current].length) {
          clearInterval(typingInterval.current);
          setTimeout(() => {
            if (isMounted.current) {
              callback();
            }
          }, 2000);
        }
      }, 100);
    };

    const deleteGoal = (callback) => {
      deletingInterval.current = setInterval(() => {
        inputRef.current.textContent = inputRef.current.textContent.slice(
          0,
          -1
        );

        if (inputRef.current.textContent.length === 0) {
          clearInterval(deletingInterval.current);
          if (isMounted.current) {
            callback();
          }
        }
      }, 100);
    };

    const animateGoals = () => {
      typeGoal(() => {
        deleteGoal(() => {
          indexRef.current = (indexRef.current + 1) % goals.length;
          animateGoals();
        });
      });
    };

    animateGoals();

    // Store the current value of inputRef
    const currentInput = inputRef.current;

    // Cleanup function
    return () => {
      isMounted.current = false;
      if (currentInput) {
        currentInput.textContent = "";
      }
      clearInterval(typingInterval.current);
      clearInterval(deletingInterval.current);
    };
  }, []);

  return (
    <Box borderRadius={2}>
      <div className="welcome">
        <h2>What have you always wanted to do?</h2>
        <div className="ad-lib">
          I've always wanted to{" "}
          <span id="standard-basic" className="ad-lib-input" ref={inputRef} />
          <span className="blinking-cursor"> </span>
        </div>
        <Button
          id="submitBtn"
          component={RouterLink}
          to="/login"
          variant="contained"
        >
          Let's Get Started!
        </Button>
      </div>
    </Box>
  );
}
