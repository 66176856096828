import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import menuContent from "./MenuContent";
import DisplayAvatar from "../DisplayAvatar";

// MUI styles
import { alpha, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";

// MUI colors and icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Navbar = ({ colorInvert = false }) => {
  const { logout } = useLogout();
  const { user, userProfile } = useAuthContext();
  const location = useLocation();
  const theme = useTheme();
  const { mode } = theme.palette;
  const [openSidebar, setOpenSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [activeLink, setActiveLink] = useState("");
  const linkColor = colorInvert ? "common.white" : "text.primary";

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  function CustomSubMenuItem(props) {
    const { title, route, onClick } = props;
    return (
      <Box marginBottom={0.5}>
        <Button
          onClick={onClick ? onClick : null}
          component={route ? RouterLink : null}
          to={route ? route : null}
          fullWidth
          sx={{
            justifyContent: "flex-start",
            color: "text.primary",
          }}
          // startIcon={p.icon || null}
        >
          {title}
        </Button>
      </Box>
    );
  }

  function CustomLogo(props) {
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const height = isLargeScreen ? 40 : 30;
    return (
      <Link
        display={"flex"}
        alignItems={"center"}
        color={colorInvert ? "common.white" : "text.primary"}
        component={RouterLink}
        to="/"
        underline="none"
      >
        <img
          src={mode === "light" ? "/logo.png" : "/logo-inverse.png"}
          alt="logo"
          height={height}
        />
      </Link>
    );
  }

  function CustomMenuItem(props) {
    const { title, route } = props;
    return (
      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          color={linkColor}
          component={RouterLink}
          to={route}
          sx={{
            textDecoration: "none",
            justifyContent: "flex-start",
            // color:
            //   activeLink === route
            //     ? theme.palette.primary.main
            //     : theme.palette.text.primary,
            fontWeight: activeLink === route ? 600 : 400,
          }}
        >
          {title}
        </Typography>
      </MenuItem>
    );
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <CustomLogo />
      <Box display={"flex"} alignItems={"center"}>
        <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
          {menuContent.map((item, i) => {
            if (!user && item.permissions !== "guest") {
              return null;
            }
            if (item.subItems) {
              return (
                <Box key={item.title} sx={{ mx: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginX: 1,
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleClick(e, i)}
                  >
                    {item.special !== "avatar" && (
                      <CustomMenuItem
                        title={item.title}
                        key={item.title}
                        sx={{
                          color:
                            activeLink === item.route
                              ? theme.palette.primary.main
                              : theme.palette.text.primary,
                          fontWeight: activeLink === item.route ? 600 : 400,
                        }}
                      />
                    )}
                    {item.special === "avatar" && userProfile && (
                      <DisplayAvatar url={userProfile.avatar_url} size="24" />
                    )}
                    {item.special === "avatar" && !userProfile && (
                      <Avatar alt={userProfile?.name} />
                    )}

                    <ExpandMoreIcon
                      sx={{
                        marginLeft: theme.spacing(0.25),
                        width: 16,
                        height: 16,
                        transform: openMenuId === i ? "rotate(180deg)" : "none",
                        color: "text.secondary",
                      }}
                    />
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuId === i}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 1,
                      sx: {
                        padding: 2,
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {item.subItems.map((subitem, i) => {
                      return (
                        <CustomSubMenuItem
                          key={subitem.title}
                          title={subitem.title}
                          route={subitem.route}
                          onClick={subitem.special === "logout" ? logout : null}
                        />
                      );
                    })}
                  </Menu>
                </Box>
              );
            }

            if (item.special === "button") {
              return (
                <Box marginLeft={2} display={user && "none"} key={item.title}>
                  <Button
                    component={item.route ? RouterLink : null}
                    to={item.route ? item.route : null}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                </Box>
              );
            }

            return (
              <Box key={i} sx={{ mx: 2 }}>
                <CustomMenuItem title={item.title} route={item.route} />
              </Box>
            );
          })}
        </Box>

        <Box
          sx={{ display: { xs: "block", md: "none" } }}
          alignItems={"center"}
        >
          <Button
            onClick={handleSidebarOpen}
            aria-label="Menu"
            variant={"outlined"}
            sx={{
              borderRadius: 2,
              minWidth: "auto",
              padding: 1,
              borderColor: alpha(theme.palette.divider, 0.2),
              marginLeft: 2,
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
      <Drawer
        anchor="left"
        onClose={() => handleSidebarClose()}
        open={openSidebar}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 280,
          },
        }}
      >
        <MenuList
          sx={{
            height: "100%",
            padding: 1,
          }}
        >
          <CustomLogo />
          <Box paddingX={2} paddingY={2}>
            {menuContent.map((item, i) => {
              if (!user && item.permissions !== "guest") {
                return null;
              }
              if (item.special === "button") {
                return (
                  <Box marginTop={2} display={user && "none"} key={item + i}>
                    <Button
                      component={item.route ? RouterLink : null}
                      to={item.route ? item.route : null}
                      onClick={() => handleSidebarClose()}
                      variant="contained"
                      color={item.color ? item.color : "primary"}
                      size="large"
                      fullWidth
                    >
                      {item.title}
                    </Button>
                  </Box>
                );
              }

              if (item.subItems) {
                return item.subItems.map((subitem, i) => {
                  return (
                    <MenuItem
                      key={subitem.title + i}
                      size={"large"}
                      component={subitem.route ? RouterLink : null}
                      to={subitem.route ? subitem.route : null}
                      onClick={() => {
                        handleSidebarClose();
                        if (subitem.special === "logout") {
                          logout();
                        }
                      }}
                      sx={{
                        justifyContent: "flex-start",
                        color:
                          activeLink === subitem.route
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === subitem.route
                            ? alpha(theme.palette.primary.main, 0.1)
                            : "transparent",
                        fontWeight: activeLink === subitem.route ? 600 : 400,
                      }}
                    >
                      {subitem.title}
                    </MenuItem>
                  );
                });
              }

              return (
                <MenuItem
                  key={item.title + i}
                  size={"large"}
                  component={item.route ? RouterLink : null}
                  to={item.route ? item.route : null}
                  onClick={() => handleSidebarClose()}
                  sx={{
                    justifyContent: "flex-start",
                    color:
                      activeLink === item.route
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    backgroundColor:
                      activeLink === item.route
                        ? alpha(theme.palette.primary.main, 0.1)
                        : "transparent",
                    fontWeight: activeLink === item.route ? 600 : 400,
                  }}
                >
                  {item.title}
                </MenuItem>
              );
            })}
          </Box>
        </MenuList>
      </Drawer>
    </Box>
  );
};

export default Navbar;
