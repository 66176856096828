import { useState, useEffect } from "react";
import supabase from "../services/supabaseClient";

export const useAvatar = (url) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const downloadImage = async (path) => {
      try {
        const { data, error } = await supabase.storage
          .from("avatars")
          .download(path);
        if (error) {
          throw error;
        }
        const url = URL.createObjectURL(data);
        setAvatarUrl(url);
      } catch (error) {
        setError("Error downloading image");
      }
    };
    if (url) downloadImage(url);
  }, [url]);

  const upload = async (event) => {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        setError("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      const fileSize = file.size;
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (fileSize > maxSize) {
        setError("File size exceeds maximum limit of 2 MB.");
        return;
      }

      let { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      setAvatarUrl(filePath);
      return filePath;
    } catch (error) {
      setError(error.message);
    } finally {
      setUploading(false);
    }
  };

  const clear = () => {
    setAvatarUrl(null);
  };

  return {
    avatarUrl,
    upload,
    uploading,
    error,
    clear,
  };
};
