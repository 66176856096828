import { useState } from "react";
import UserGoalsList from "./UserGoalsList";
import AddUserGoal from "./AddUserGoal";
import { useAuthContext } from "hooks/useAuthContext";
import { useUserGoalsContext } from "hooks/useUserGoalsContext";

// MUI styles
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function UserGoalTable() {
  const [tab, setTab] = useState(0);
  const { goals, error, userId, addUserGoal, editing } = useUserGoalsContext();
  const { user } = useAuthContext();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      {error && error}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChange} aria-label="Goals">
          <Tab label="In Progress" index={0} />
          <Tab label="Completed" index={1} />
          {editing && <Tab label="Archived" index={2} />}
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        {goals.openGoals && <UserGoalsList category="openGoals" />}
        {goals.openGoals && goals.openGoals.length === 0 && (
          <p>No open goals yet - add one below!</p>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            pt: 4,
          }}
        >
          {user && user.id === userId && (
            <AddUserGoal onAdd={addUserGoal} userId={userId} />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {goals.completedGoals && <UserGoalsList category="completedGoals" />}
        {goals.completedGoals && goals.completedGoals.length === 0 && (
          <p>No completed goals yet.</p>
        )}
      </TabPanel>
      {editing && (
        <TabPanel value={tab} index={2}>
          {goals.archivedGoals && <UserGoalsList category="archivedGoals" />}
          {goals.archivedGoals && goals.archivedGoals.length === 0 && (
            <p>No archived goals yet.</p>
          )}
        </TabPanel>
      )}
    </Box>
  );
}
