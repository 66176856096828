import { useState } from "react";
import PropTypes from "prop-types";
import Container from "theme/Container";
import { useAuthContext } from "hooks/useAuthContext";
import CommentEditor from "../components/CommentEditor";

// mui styles
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function AddComment({ onAddComment, postId }) {
  const [newContentJSON, setNewContentJSON] = useState("");
  const [newContentHTML, setNewContentHTML] = useState("");
  const [error, setError] = useState(null);
  const [resetContent, setResetContent] = useState(false);
  const { user, userProfile } = useAuthContext();
  const [isEmpty, setIsEmpty] = useState(true);

  AddComment.propTypes = {
    onAddComment: PropTypes.func,
    postId: PropTypes.number,
  };

  const handleContentChange = (newContentJSON, newContentHTML) => {
    setNewContentJSON(newContentJSON);
    setNewContentHTML(newContentHTML);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAdd();
  };

  const handleAdd = async () => {
    if (isEmpty) {
      setError("Comment cannot be empty");
      return;
    }

    const comment = {
      user_id: user.id,
      blog_post_id: postId,
      content_json: newContentJSON,
      content_html: newContentHTML,
    };

    try {
      onAddComment(comment, userProfile.username);
    } catch (error) {
      setError(error);
    } finally {
      setResetContent(!resetContent);
    }
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant={"subtitle1"} fontWeight={700}>
                      Leave a comment
                    </Typography>
                    {error && <p>{error}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <CommentEditor
                      onContentChange={handleContentChange}
                      resetContent={resetContent}
                      setIsEmpty={setIsEmpty}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ xs: "stretched", sm: "center" }}
                      justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                      width={1}
                      margin={"0 auto"}
                    >
                      <Button
                        size={"large"}
                        variant={"contained"}
                        type={"submit"}
                      >
                        Post
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
