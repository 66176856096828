import { useState } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Blog from "./blog/Blog";
import useUserGoalLike from "hooks/useUserGoalLike";
import { useSingleUserGoal } from "hooks/useSingleUserGoal";

// MUI styles
import Chip from "@mui/material/Chip";
import BoltIcon from "@mui/icons-material/Bolt";
import Button from "@mui/material/Button";
import CompleteGoal from "./CompleteGoal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function UserGoal() {
  const { user } = useAuthContext();
  const { userGoalId: id, username, goalSlug: slug } = useParams();
  const {
    userGoal,
    error,
    loading,
    completeUserGoal,
    removeUserGoalTag,
    addUserGoalTag,
  } = useSingleUserGoal(id, username, slug);

  const [showComplete, setShowComplete] = useState(false);
  const { liked, toggleLike } = useUserGoalLike(id, user);

  const handleLike = () => {
    toggleLike();
  };

  const handleShowComplete = () => {
    setShowComplete(!showComplete);
  };

  const handleRemoveTag = (id) => {
    removeUserGoalTag(id);
  };

  return (
    <>
      {error && <p>{error}</p>}
      {loading && <p>{loading}</p>}
      {!error && userGoal && (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Button
              variant="text"
              component={RouterLink}
              to={userGoal.isOwner ? `/` : `/profiles/${userGoal.username}`}
            >
              {`Back to ${username}'s profile`}
            </Button>
            <h1>{userGoal.goalTitle}</h1>
            <h3>{userGoal.goalDescription}</h3>
          </Grid>
          <Grid item xs={6}>
            <Chip label={userGoal.isCompleted ? "Done" : "Doing"} />
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              color={liked ? "secondary" : "primary"}
              aria-label="upload picture"
              component="label"
              variant="contained"
              endIcon={<BoltIcon />}
              onClick={handleLike}
            >
              {liked ? "Uncheer" : "Cheer"}
            </Button>

            {!userGoal.isCompleted && userGoal.isOwner && (
              <Button
                color="primary"
                aria-label="upload picture"
                component="label"
                variant="contained"
                onClick={handleShowComplete}
              >
                I Did It!
              </Button>
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            {!userGoal.isOwner &&
              userGoal.tags &&
              userGoal.tags.map((tag) => (
                <Chip key={tag.tag} variant="outlined" label={tag.tag} />
              ))}
            {userGoal.isOwner && userGoal.tags && (
              <Autocomplete
                multiple
                id="tags-input"
                options={[]}
                value={userGoal.tags}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.tag}
                      {...getTagProps({ index })}
                      onDelete={() => {
                        handleRemoveTag(option);
                      }}
                    />
                  ))
                }
                onChange={(event, value) => {
                  addUserGoalTag(value[value.length - 1]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Tags"
                    placeholder="Add a tag"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <h2>Entries</h2>
            <Blog
              userGoalId={userGoal.id}
              userId={userGoal.userId}
              userName={userGoal.username}
              slug={userGoal.goalSlug}
            />
          </Grid>

          {userGoal.isOwner && (
            <CompleteGoal
              title={userGoal.goalTitle}
              open={showComplete}
              completeUserGoal={completeUserGoal}
              handleClose={handleShowComplete}
              id={userGoal.id}
            />
          )}
        </Grid>
      )}
    </>
  );
}
