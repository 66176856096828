import { useState } from "react";
import Container from "theme/Container";
import { useAuthContext } from "../../../../../hooks/useAuthContext";
import Editor from "../components/PostEditor";

// mui styles
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

export default function AddPost({ onAdd, userId, userGoalId, slug }) {
  const [title, setTitle] = useState("");
  const [newContentJSON, setNewContentJSON] = useState("");
  const [newContentHTML, setNewContentHTML] = useState("");
  const [error, setError] = useState(null);
  const [privatePost, setPrivatePost] = useState(false);
  const [resetContent, setResetContent] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const { user } = useAuthContext();

  const handleContentChange = (newContentJSON, newContentHTML) => {
    setNewContentJSON(newContentJSON);
    setNewContentHTML(newContentHTML);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAdd();
  };

  const handleAdd = async () => {
    if (userId !== user.id) {
      return;
    }

    if (isEmpty) {
      setError("Post cannot be empty");
      return;
    }

    const post = {
      title: title,
      isPrivate: privatePost,
      userId: userId,
      userGoalId: userGoalId,
      contentJson: newContentJSON,
      contentHtml: newContentHTML,
      goalSlug: slug,
    };

    try {
      await onAdd(post);
    } catch (error) {
      setError(error);
    } finally {
      setTitle("");
      setResetContent(true);
      setNewContentJSON(null);
      setNewContentHTML(null);
    }
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant={"subtitle1"} fontWeight={700}>
                      Post an update about this
                    </Typography>
                    {error && <p>{error}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      name={"title"}
                      value={title}
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <TextField
                      label="How's it going?"
                      variant="outlined"
                      name={"content"}
                      multiline
                      rows={5}
                      value={content}
                      required
                      onChange={(e) => setContent(e.target.value)}
                      fullWidth
                    /> */}
                    <Editor
                      onContentChange={handleContentChange}
                      resetContent={resetContent}
                      setIsEmpty={setIsEmpty}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ xs: "stretched", sm: "center" }}
                      justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                      width={1}
                      margin={"0 auto"}
                    >
                      <Box
                        marginBottom={{ xs: 1, sm: 0 }}
                        marginRight={{ sm: 1 }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch />}
                            label="Private"
                            checked={privatePost}
                            onChange={(e) => setPrivatePost(e.target.checked)}
                          />
                        </FormGroup>
                      </Box>
                      <Button
                        size={"large"}
                        variant={"contained"}
                        type={"submit"}
                      >
                        Post
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
