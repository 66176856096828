import { useAuthContext } from "./useAuthContext";
import supabase from "../services/supabaseClient";

export const useLogin = () => {
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    const response = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (response.error) {
      throw new Error(response.error.message);
    }
    dispatch({ type: "LOGIN", payload: response.data.user });
  };

  return { login };
};
