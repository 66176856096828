import React from "react";
import usePostLike from "hooks/usePostLike";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import CelebrationIcon from "@mui/icons-material/Celebration";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";

export default function PostLike({
  postId,
  userId,
  startingLikes,
  setTempLikes,
}) {
  const { liked, toggleLike } = usePostLike(postId, userId);
  const [likes, setLikes] = React.useState(startingLikes);

  const handleLike = () => {
    toggleLike();
    if (liked) {
      setLikes(likes - 1);
      setTempLikes(likes - 1);
    } else {
      setLikes(likes + 1);
      setTempLikes(likes + 1);
    }
  };
  return (
    <Tooltip arrow title={liked ? "Uncheer" : "Cheer"} enterDelay={400}>
      <IconButton
        color={liked ? "secondary" : "primary"}
        aria-label="upload picture"
        component="label"
        onClick={handleLike}
        sx={{
          fontSize: "inherit",
          verticalAlign: "text-top",
          marginLeft: 1,
        }}
      >
        {liked ? <CelebrationIcon /> : <CelebrationOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
}
