import { UserGoalsProvider } from "context/UserGoalsContext";
import UserGoalsTable from "./UserGoalsTable";

export default function UserGoals({ userId, editing }) {
  return (
    <UserGoalsProvider userId={userId} editing={editing}>
      <UserGoalsTable />
    </UserGoalsProvider>
  );
}
