import { useState, useEffect } from "react";
import supabase from "../services/supabaseClient";

export const useSignup = () => {
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const signup = async (email, password, displayName) => {
    setIsPending(true);

    //check if displayname is taken
    const { data: profiles, profileError } = await supabase
      .from("profiles")
      .select("username")
      .ilike("username", displayName);
    if (profileError) {
      console.log("profile error:", profileError);
    }
    if (profiles.length > 0) {
      throw new Error("Username is taken");
    }

    // Create user
    const response = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          username: displayName,
        },
      },
    });
    if (response.error) {
      setIsPending(false);
      console.log("signup response:", response);
      throw new Error(response.error.message);
    }

    // cleanup
    if (!isCancelled) {
      setIsPending(false);
    }
  };

  useEffect(() => {
    setIsCancelled(false);
    return () => setIsCancelled(true);
  }, []);

  return { isPending, signup };
};
