import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { profilePropType } from "propTypes/profilePropTypes";
import supabase from "../services/supabaseClient";

export const usePeople = (user) => {
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  usePeople.propTypes = {
    goals: PropTypes.arrayOf(profilePropType),
  };

  function setPeopleData(data) {
    PropTypes.checkPropTypes(
      { people: PropTypes.arrayOf(profilePropType) },
      { people: data },
      "people",
      "setPeople"
    );
    setPeople(data);
  }

  useEffect(() => {
    const fetchPeople = async () => {
      setLoading(true);

      let query = supabase
        .from("profiles")
        .select("*")
        .is("is_private", false)
        .order("username");

      if (user) {
        query = query.neq("id", user.id);
      }

      const { data, error } = await query;

      if (error) {
        setError("An error has occured.");
        setLoading(false);
      } else {
        const formattedPeople = data.map((item) => {
          return {
            id: item.id,
            updatedAt: item.updated_at,
            username: item.username,
            avatarUrl: item.avatar_url,
            bio: item.bio,
            location: item.location,
            birthday: item.birthday,
            website: item.website,
            firstName: item.first_name,
            lastName: item.last_name,
            pronouns: item.pronouns,
            isPrivate: item.is_private,
          };
        });

        setPeopleData(formattedPeople);
        setLoading(false);
      }
    };
    fetchPeople();
  }, [user]);

  return {
    people,
    loading,
    error,
  };
};
