import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

// MUI styles
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function ForgotPassword() {
  const { error, resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [resetComplete, setResetComplete] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      resetPassword(email);
      setResetComplete(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {!resetComplete ? (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset
              </Button>
              {error && <p>{error}</p>}
            </Box>
          </Box>
        </Container>
      ) : (
        <Typography variant="body1" align="center">
          Please check your email and follow the instructions to reset your
          password.
        </Typography>
      )}
    </div>
  );
}
