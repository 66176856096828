import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ThemeModeToggler from "../theme/ThemeModeToggler";

const Footer = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display={"flex"} alignItems={"center"} color={"primary.dark"}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                align={"right"}
                variant={"subtitle2"}
                color="text.secondary"
                gutterBottom
              >
                <Link
                  underline="none"
                  color="text.secondary"
                  href="https://www.identitymad.com/"
                >
                  Identity Mad
                </Link>
                &nbsp;&copy; 2023{" "}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Link
                underline="none"
                color="text.secondary"
                variant={"subtitle2"}
                component={RouterLink}
                to="/about"
              >
                About
              </Link>
            </Box>
            <Box marginLeft={{ xs: 2, sm: 4 }}>
              <Link
                underline="none"
                color="text.secondary"
                variant={"subtitle2"}
                component={RouterLink}
                to="/about/contact"
              >
                Contact
              </Link>
            </Box>
            <Box marginLeft={{ xs: 2, sm: 4 }}>
              <Link
                underline="none"
                variant={"subtitle2"}
                color="text.secondary"
                component={RouterLink}
                to="/about/guidelines"
              >
                Guidelines
              </Link>
            </Box>
            <Box marginX={{ xs: 2, sm: 4 }}>
              <Link
                underline="none"
                color="text.secondary"
                variant={"subtitle2"}
                component={RouterLink}
                to="/about/terms-of-service"
              >
                Terms of Service
              </Link>
            </Box>
            <ThemeModeToggler />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
