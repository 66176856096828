import { useParams } from "react-router-dom";
import UserGoals from "../../shared/userGoals/UserGoals";
import UserProfileBio from "./UserProfileBio";
import Container from "theme/Container";
import { useSingleProfile } from "hooks/useSingleProfile";

// mui styles
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function UserProfile() {
  const { username } = useParams();
  const { profile, loading, error } = useSingleProfile(username);

  // display the user's profile

  return (
    <Container>
      <Box>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            {error && <p>{error}</p>}
            {profile && profile.id && (
              <>
                <h2>{`${profile.username}'s goals`}</h2>
                <UserGoals userId={profile.id} editable={false} />
              </>
            )}
            {loading && <h1>Loading...</h1>}
          </Grid>
          <Grid item xs={12} md={4}>
            {profile && <UserProfileBio profile={profile} />}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
