import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import Container from "theme/Container";
import EditAvatar from "./EditAvatar";
import useAlert from "hooks/useAlert";
import { useSingleProfile } from "hooks/useSingleProfile";
import { Link as RouterLink } from "react-router-dom";

// mui styles
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// mui dates
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

export default function EditProfile() {
  const { user, userProfile } = useAuthContext();
  const { setAlert } = useAlert();

  const { profile, updateProfile, loading, error } = useSingleProfile(
    userProfile?.username
  );

  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [birthday, setBirthday] = useState("");
  const [location, setLocation] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (userProfile && profile && !loading && !error) {
      setUsername(profile.username ? profile.username : "");
      setBio(profile.bio ? profile.bio : "");
      setFirstName(profile.firstName ? profile.firstName : "");
      setLastName(profile.lastName ? profile.lastName : "");
      setWebsite(profile.website ? profile.website : "");
      setPronouns(profile.pronouns ? profile.pronouns : "");
      setBirthday(profile.birthday ? profile.birthday : null);
      setLocation(profile.location ? profile.location : "");
      setAvatarUrl(profile.avatarUrl ? profile.avatarUrl : "");
    }
  }, [userProfile, profile, loading, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const birthdayFormatted = moment(birthday).toISOString();

    const profileData = {
      id: user.id,
      username,
      bio,
      firstName,
      lastName,
      website,
      pronouns,
      birthday: birthdayFormatted,
      location,
      avatarUrl,
    };

    try {
      await updateProfile(profileData);
      setAlert("Profile updated successfully!", "success");
    } catch (error) {
      setAlert(error.message, "error");
    }
  };

  return (
    <Container>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              Update your profile
            </Typography>
            <Typography variant={"subtitle2"} color={"text.secondary"}>
              Please read our{" "}
              <Link
                color={"primary"}
                component={RouterLink}
                to="/about/terms-of-service"
                underline={"none"}
              >
                terms of use
              </Link>{" "}
              to be informed how we manage your private data. To delete your
              account, please{" "}
              <Link
                component={RouterLink}
                to="/about/contact"
                underline={"none"}
              >
                contacting our support team
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant={"subtitle1"} fontWeight={700}>
                    Account details
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Username"
                    variant="outlined"
                    name={"username"}
                    fullWidth
                    required
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    helperText={`thingbything.com/profile/${username}`}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                  <EditAvatar
                    url={avatarUrl}
                    size={150}
                    onUpload={(url) => {
                      setAvatarUrl(url);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant={"subtitle1"} fontWeight={700}>
                    About you
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Bio"
                    variant="outlined"
                    name={"bio"}
                    multiline
                    rows={5}
                    fullWidth
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Website"
                    variant="outlined"
                    name={"website"}
                    fullWidth
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant={"subtitle1"} fontWeight={700}>
                    Personal information
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First name"
                    variant="outlined"
                    name={"firstName"}
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last name"
                    variant="outlined"
                    name={"lastName"}
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Pronouns"
                    variant="outlined"
                    name={"pronouns"}
                    helperText="e.g. she/her/hers"
                    fullWidth
                    value={pronouns}
                    onChange={(e) => setPronouns(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="year"
                      views={["year", "month", "day"]}
                      label="Birthday"
                      value={birthday}
                      onChange={(newValue) => {
                        setBirthday(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item container xs={12}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "stretched", sm: "center" }}
                    justifyContent={"space-between"}
                    width={1}
                    margin={"0 auto"}
                  >
                    <Box marginBottom={{ xs: 1, sm: 0 }}></Box>
                    <Button
                      size={"large"}
                      variant={"contained"}
                      type={"submit"}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
