import React from "react";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

export default function Banner() {
  return (
    <Alert severity="info" sx={{ mb: 5 }}>
      <Typography variant="body1">
        <Typography component="span" fontWeight="bold">
          Welcome to our shiny new website!
        </Typography>{" "}
        While we're still getting everything up and running, we want you to know
        that we're making daily updates, bug fixes, and improvements to ensure
        you have the most thrilling user experience possible.
      </Typography>
    </Alert>
  );
}
