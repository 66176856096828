import { useState, useEffect } from "react";
import supabase from "services/supabaseClient";

export default function usePostLike(postId, userId) {
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    async function fetchLiked() {
      const { data, error } = await supabase
        .from("blog_likes")
        .select("id")
        .eq("blog_post_id", postId)
        .eq("user_id", userId);

      if (error) {
        setLiked(false);
      } else {
        setLiked(data.length > 0);
      }
    }

    fetchLiked();
  }, [postId, userId]);

  async function toggleLike() {
    if (liked) {
      await supabase
        .from("blog_likes")
        .delete()
        .eq("blog_post_id", postId)
        .eq("user_id", userId);
    } else {
      await supabase
        .from("blog_likes")
        .insert({ blog_post_id: postId, user_id: userId });
    }

    setLiked(!liked);
  }

  return { liked, toggleLike };
}
