import { useEffect, createContext, useReducer } from "react";
import supabase from "../services/supabaseClient";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_PROFILE":
      return {
        ...state,
        user: action.payload.user,
        userProfile: action.payload.userProfile,
      };
    case "LOGOUT":
      return { ...state, user: null, userProfile: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    userProfile: null,
  });

  useEffect(() => {
    async function getSession() {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      dispatch({ type: "AUTH_IS_READY", payload: session?.user ?? null });
    }

    getSession();

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session && event === "PASSWORD_RECOVERY") {
          let newPassword = "";

          while (!newPassword || newPassword.length < 6) {
            newPassword = prompt(
              "What would you like your new password to be?"
            );

            if (!newPassword || newPassword.length < 6) {
              alert(
                "Password must be at least 6 characters long and not blank."
              );
            }
          }

          const { data, error } = await supabase.auth.updateUser({
            password: newPassword,
          });

          if (error) alert("There was an error updating your password.");
          if (!error && data) alert("Password updated successfully!");
        }
        if (session && (event === "SIGNED_IN" || event === "TOKEN_REFRESHED")) {
          dispatch({ type: "AUTH_IS_READY", payload: session.user });
        } else if (event === "SIGNED_OUT") {
          dispatch({ type: "AUTH_IS_READY", payload: null });
        }
      }
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (state.user) {
      const profileRow = `id=eq.${state.user?.id}`;
      const profileSubscription = supabase
        .channel("any")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "profiles",
            filter: profileRow,
          },
          (payload) => {
            dispatch({
              type: "SET_PROFILE",
              payload: { user: state.user, userProfile: payload.new },
            });
          }
        )
        .subscribe();

      return () => {
        profileSubscription.unsubscribe();
      };
    }
  }, [state.user]);

  useEffect(() => {
    async function getProfile() {
      const { data: userProfile, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", state.user.id)
        .single();

      if (error) {
        throw new Error("Could not get profile");
      }

      dispatch({
        type: "SET_PROFILE",
        payload: { user: state.user, userProfile },
      });
    }

    if (state.user) {
      getProfile();
    }
  }, [state.user]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
