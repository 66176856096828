import PropTypes from "prop-types";

export const postPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userGoalId: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  username: PropTypes.string,
  content: PropTypes.object,
  numComments: PropTypes.number.isRequired,
  numLikes: PropTypes.number.isRequired,
  goalSlug: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      postId: PropTypes.number.isRequired,
      content: PropTypes.object.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
});
