import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const lastUpdated = "March 15, 2023";

export default function TOSContent() {
  return (
    <Box>
      <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
        By using the Ulpengo website and its services, you agree to the
        following terms and conditions. Please read them carefully before using
        the site. If you do not agree with any part of these terms, please
        discontinue your use of the site.
      </Typography>
      <Typography variant="h5" paragraph>
        User Conduct and Content
      </Typography>
      <Typography variant="body1" paragraph>
        As a user of Ulpengo, you are responsible for any content you post on
        the site, including text, images, and links. You agree not to post any
        content that is illegal, harmful, threatening, abusive, defamatory, or
        otherwise objectionable. You also agree not to impersonate other
        individuals or entities, engage in spamming or other disruptive
        behaviors, or violate any intellectual property rights of others.
      </Typography>
      <Typography variant="h5" paragraph>
        Account Termination
      </Typography>
      <Typography variant="body1" paragraph>
        Ulpengo reserves the right to terminate or suspend your account, without
        prior notice or liability, if you are found to have violated any of the
        terms and conditions listed here. You may also terminate your account at
        any time by{" "}
        <Link component={RouterLink} to="/about/contact">
          contacting our support team
        </Link>
        .
      </Typography>
      <Typography variant="h5" paragraph>
        Disclaimers and Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Ulpengo is provided "as is" without any warranties, express or implied.
        We do not guarantee the accuracy, reliability, or completeness of any
        content on the site. Under no circumstances shall Ulpengo be liable for
        any damages arising out of your use of the site or its services.
      </Typography>
      <Typography variant="h5" paragraph>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Your privacy is important to us at Ulpengo. This privacy policy explains
        how we collect, use, and protect your personal information when you use
        our website and services.
      </Typography>
      <Typography variant="h6" paragraph>
        Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We collect personal information when you register for an account, create
        a profile, and interact with our services. This information may include
        your name, email address, and any content you submit on the site.
      </Typography>
      <Typography variant="h6" paragraph>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use your personal information to provide, maintain, and improve our
        services, as well as to communicate with you and personalize your
        experience on the site. We may also use your information for security
        purposes and to comply with legal obligations.
      </Typography>
      <Typography variant="h6" paragraph>
        Information Sharing and Disclosure
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell or rent your personal information to third parties. We
        may share your information with third-party service providers who help
        us operate the site and provide our services, as long as they agree to
        protect your information in accordance with this privacy policy.
      </Typography>
      <Typography variant="h6" paragraph>
        Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to protect your personal information from
        unauthorized access, alteration, or destruction. However, no method of
        data transmission or storage can guarantee complete security, so we
        cannot ensure or warrant the security of your information.
      </Typography>
      <Typography variant="h5" paragraph>
        Changes to This Privacy Policy and Terms of Service
      </Typography>

      <Typography variant="body1" paragraph>
        Ulpengo reserves the right to update or modify these Terms of Service
        and Privacy Policy at any time without prior notice. We encourage you to
        periodically review this page for the latest information on our
        practices. Your continued use of the site and services constitutes your
        agreement to the updated terms and policies.
      </Typography>
      <Typography variant="h5" paragraph>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns regarding our Terms of Service or
        Privacy Policy, please do not hesitate to contact us at
        support@thingbything.com. We are committed to addressing your concerns
        and ensuring that your experience on our site is both enjoyable and
        secure.
      </Typography>
    </Box>
  );
}
