import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";
import useAlert from "hooks/useAlert";
import Banner from "shared/Banner";

// MUI styles
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const { signup, isPending } = useSignup();
  const [signupComplete, setSignupComplete] = useState(false);
  const { setAlert } = useAlert();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password, displayName);
      setSignupComplete(true);
    } catch (error) {
      setAlert(error.message, "error");
    }
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

  return (
    <div>
      <Banner />
      {!signupComplete ? (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="displayName"
                label="Display Name"
                name="displayName"
                autoComplete="displayName"
                autoFocus
                value={displayName}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                    return;
                  }
                  setDisplayName(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isPending}
              >
                {isPending ? "Loading..." : "Sign Up"}
              </Button>

              <Grid container>
                <Grid item>
                  <Link component={RouterLink} to="/login" variant="body2">
                    {"Already have any account? Log In"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      ) : (
        <Typography variant="body1" align="center">
          Thank you for signing up! Please check your email and follow the
          confirmation link to complete the registration process.
        </Typography>
      )}
    </div>
  );
}
