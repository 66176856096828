import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import supabase from "../services/supabaseClient";
import { useAuthContext } from "hooks/useAuthContext";
import { userGoalPropType } from "propTypes/userGoalPropTypes";

export const useSingleUserGoal = (id, username, slug) => {
  const { user } = useAuthContext();
  const [userGoal, setUserGoalPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useSingleUserGoal.propTypes = {
    userGoal: userGoalPropType,
  };

  function setUserGoalData(data) {
    PropTypes.checkPropTypes(
      { posts: userGoalPropType },
      { posts: data },
      "userGoal",
      "setUserGoalPost"
    );
    setUserGoalPost(data);
  }

  useEffect(() => {
    if (!user) return;
    const fetchPost = async () => {
      setLoading(true);

      const { data: userGoalData, error } = await supabase
        .from("user_goal")
        .select(
          "*, profiles(username), goals(title, description, slug), num_likes:user_goal_likes(count), goal_stats(num_users), num_posts:blog_posts(count), user_goal_tags(tags(id, tag))"
        )
        .eq("id", id)
        .single();

      if (error) {
        setError(error);
      } else if (!userGoalData) {
        setError("This user goal does not exist.");
      } else if (userGoalData.profiles.username !== username) {
        setError("This user does not exist.");
      } else if (userGoalData.goals.slug !== slug) {
        setError("This user goal does not exist.");
      } else {
        const tags = userGoalData.user_goal_tags.map((obj) => ({
          id: obj.tags.id,
          tag: obj.tags.tag,
        }));

        const formattedUserGoal = {
          id: userGoalData.id,
          createdAt: userGoalData.created_at,
          userId: userGoalData.user_id,
          goalId: userGoalData.goal_id,
          isPrivate: userGoalData.is_private,
          isCompleted: userGoalData.is_completed,
          username: userGoalData.profiles.username,
          goalTitle: userGoalData.goals.title,
          goalSlug: userGoalData.goals.slug,
          numUsers: userGoalData.goal_stats.num_users,
          numLikes: userGoalData.num_likes[0].count + 1,
          numPosts: userGoalData.num_posts[0].count,
          goalDescription: userGoalData.goals.description,
          isOwner: user && user.id === userGoalData.user_id,
          tags: tags,
        };

        setUserGoalData(formattedUserGoal);
        setLoading(false);
      }
    };
    fetchPost();
  }, [id, username, slug, user]);

  const completeUserGoal = async (userGoalData) => {
    const updates = {
      review: userGoalData.review,
      rating: userGoalData.rating,
      completed_at: userGoalData.completedAt,
      is_completed: true,
    };

    const { updateError } = await supabase
      .from("user_goal")
      .update(updates)
      .eq("id", userGoal.id);

    if (updateError) {
      setError("Network response was not ok");
    } else {
      setUserGoalData({
        ...userGoal,
        isCompleted: true,
        review: userGoalData.review,
        rating: userGoalData.rating,
        completedAt: userGoalData.completedAt,
      });
    }
  };

  const removeUserGoalTag = async (tag) => {
    const { deleteError } = await supabase
      .from("user_goal_tags")
      .delete()
      .eq("user_goal_id", userGoal.id)
      .eq("tag_id", tag.id);

    if (deleteError) {
      setError("Network response was not ok");
    } else {
      const newTags = userGoal.tags.filter((t) => t.tag !== tag.tag);
      setUserGoalData({
        ...userGoal,
        tags: newTags,
      });
    }
  };

  const addUserGoalTag = async (tag) => {
    let newTagId = null;
    // Check if tag exists in tags table
    let { data: tagData, error: tagError } = await supabase
      .from("tags")
      .select("id")
      .eq("tag", tag);

    if (tagError) {
      throw tagError;
    }

    if (tagData.length !== 0) {
      // if tag exists, get some data about the tag
      newTagId = tagData[0].id;
    } else {
      // If global tag doesn't exist, create it
      const { data: insertData, error: insertError } = await supabase
        .from("tags")
        .insert({ tag: tag })
        .select()
        .single();

      if (insertError) {
        throw insertError;
      }
      newTagId = insertData.id;
    }

    // Create user_goal
    const { data: ugTagData, error: ugTagError } = await supabase
      .from("user_goal_tags")
      .insert({ user_id: user.id, tag_id: newTagId, user_goal_id: userGoal.id })
      .select()
      .single();

    if (ugTagError) {
      throw error;
    }

    const newTags = [...userGoal.tags, { id: ugTagData.id, tag: tag }];
    setUserGoalData({
      ...userGoal,
      tags: newTags,
    });
  };

  return {
    userGoal,
    loading,
    error,
    removeUserGoalTag,
    completeUserGoal,
    addUserGoalTag,
  };
};
