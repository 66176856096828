// AuthContext.js
import { createContext, useState } from "react";

const AlertContext = createContext({
  setAlert: () => {},
});

export const AlertProvider = ({ children }) => {
  const [text, setText] = useState("");
  const [type, setType] = useState("info");
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(5000);

  const setAlert = (text, type) => {
    setText(text);
    setType(type);
    setOpen(true);
    setDuration(duration);
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        type,
        open,
        duration,
        setAlert,
        setOpen,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
