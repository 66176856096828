import React from "react";
import { Link as RouterLink } from "react-router-dom";

//mui imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const lastUpdated = "March 20, 2023";

export default function AboutContent() {
  return (
    <Box>
      <Typography variant="h5" paragraph>
        This is community that's all about supporting each other's goals and
        dreams.
      </Typography>

      <Typography variant="body1" paragraph>
        We want to make sure that everyone feels safe and respected here, so we
        have a few guidelines that we ask everyone to follow.
      </Typography>

      <Typography variant="body1" paragraph>
        It's really important to be supportive of other members. We're all here
        to encourage each other, so please be positive and kind in your
        interactions. Please be respectful of other people's opinions and
        beliefs. We're a diverse community, and it's important that everyone
        feels valued and heard. We have a zero-tolerance policy for spam,
        harassment, and any kind of harmful or abusive behavior. If you see
        something that violates our guidelines, please{" "}
        <Link component={RouterLink} to="/about/contact">
          contact our support team
        </Link>
        .
      </Typography>

      <Typography
        variant="subtitle1"
        paragraph
        sx={{
          fontWeight: "bold",
        }}
      >
        The following is not allowed:
      </Typography>

      <Typography variant="body1" component="ul" paragraph>
        <li>Uploading content that isn't yours or that violates copyright</li>
        <li>Spamming the site or using it for commercial purposes</li>
        <li>
          Offering unwanted advances or posting inappropriate or suggestive
          photos
        </li>
        <li>
          Posting content that includes nudity, genitalia, or intimate moments
        </li>
        <li>
          Posting harmful advice, such as instructions on how to hurt yourself
          or others
        </li>
      </Typography>

      <Typography variant="body1" paragraph>
        We want everyone to feel safe and respected in this community, so please
        follow these guidelines and report any violations you see.
      </Typography>
    </Box>
  );
}
