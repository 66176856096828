import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { goalPropType } from "propTypes/goalPropTypes";
import supabase from "../services/supabaseClient";

export const useSingleGoal = (id, slug) => {
  const [goal, setGoal] = useState(null);
  const [topUsers, setTopUsers] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [topTags, setTopTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useSingleGoal.propTypes = {
    goals: PropTypes.arrayOf(goalPropType),
  };

  useEffect(() => {
    if (!id) return;
    function setGoalData(data) {
      PropTypes.checkPropTypes(
        { goals: goalPropType },
        { goals: data },
        "goal",
        "setGoal"
      );
      setGoal(data, slug);
    }

    const fetchGoal = async () => {
      setLoading(true);
      const { data, error } = await supabase
        .from("goals")
        .select("*, num_users:user_goal(count)")
        .eq("id", id)
        .single();

      if (error) {
        setError("Goals not found.");
        setLoading(false);
      } else if (slug !== data.slug) {
        setError("Goals not found.");
        setLoading(false);
      } else {
        const formattedGoal = {
          id: data.id,
          createdAt: data.created_at,
          title: data.title,
          description: data.description,
          slug: data.slug,
          isApproved: data.is_approved,
          numUsers: data.num_users[0].count,
        };

        setGoalData(formattedGoal);

        setLoading(false);
      }
    };
    fetchGoal();

    const fetchTopUsers = async () => {
      const { data: userData, error: userError } = await supabase
        .from("goal_leaderboard")
        .select("*")
        .eq("goal_id", id)
        .is("is_private", false)
        .order("num_likes", { ascending: false })
        .limit(3);

      if (userError) {
        console.log(userError);
      } else {
        setTopUsers(userData);
      }
    };

    fetchTopUsers();

    const fetchNewUsers = async () => {
      const { data: userGoalData, error: userGoalError } = await supabase
        .from("user_goal")
        .select("profiles(username)")
        .eq("goal_id", id)
        .is("is_private", false)
        .order("created_at", { ascending: false })
        .limit(3);

      if (userGoalError) {
        console.log(userGoalError);
      } else {
        const formattedUserGoalData = userGoalData.map((item) => {
          return {
            username: item.profiles.username,
          };
        });
        setNewUsers(formattedUserGoalData);
      }
    };

    fetchNewUsers();

    const fetchPosts = async () => {
      const { data: postData, error: postError } = await supabase
        .from("blog_posts")
        .select("*, goal_id:user_goal!inner(goals!inner(id))")
        .eq("user_goal.goals.id", id)
        .is("is_private", false)
        .order("created_at", { ascending: false })
        .limit(3);

      if (postError) {
        console.log(postError);
      } else {
        setRecentPosts(postData);
      }
    };

    fetchPosts();

    const fetchTags = async () => {
      const { data: tagData, error: tagError } = await supabase
        .from("user_goal_tag_counts")
        .select("tag")
        .eq("goal_id", id)
        .order("num_users", { ascending: false })
        .limit(5);

      if (tagError) {
        console.log(tagError);
      } else {
        setTopTags(tagData);
      }
    };
    fetchTags();
  }, [id, slug]);

  return {
    goal,
    recentPosts,
    topUsers,
    newUsers,
    topTags,
    loading,
    error,
  };
};
