import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "theme/Container";
import { useAuthContext } from "../../../../../hooks/useAuthContext";
import Editor from "../components/PostEditor";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { useSinglePost } from "hooks/useSinglePost";

// mui styles
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

// route path: /profile/:username/:userGoalId/:goalSlug/:postId/edit
// example: /profile/username/1/first-goal/1/edit

export default function EditPost() {
  const { user } = useAuthContext();
  const { username, userGoalId, goalSlug, postId } = useParams();
  const { post, updatePost, loading, error } = useSinglePost(
    username,
    userGoalId,
    goalSlug,
    postId
  );

  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [previousContent, setPreviousContent] = useState(null);
  const [newContentJSON, setNewContentJSON] = useState("");
  const [newContentHTML, setNewContentHTML] = useState("");
  const [privatePost, setPrivatePost] = useState(false);
  const [postOwner, setPostOwner] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleContentChange = (newContentJSON, newContentHTML) => {
    setNewContentJSON(newContentJSON);
    setNewContentHTML(newContentHTML);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleUpdate();
  };

  useEffect(() => {
    if (!loading && !error) {
      setTitle(post.title ? post.title : "");
      setPrivatePost(post.isPrivate ? post.isPrivate : false);
      setPreviousContent(post.content ? post.content : " ");
      setPostOwner(post.userId ? post.userId : false);
    }
  }, [loading, error, post]);

  const handleUpdate = async () => {
    if (postOwner !== user.id) {
      return;
    }

    if (isEmpty) {
      setAlert("Post cannot be empty", "error");
      return;
    }

    const postData = {
      title,
      privatePost,
      newContentJSON,
      newContentHTML,
    };
    try {
      await updatePost(postData);
      navigate(-1);
    } catch (error) {
      setAlert(error.message, "error");
    }
  };

  return (
    <>
      <Container>
        <Box>
          <Grid container spacing={4}>
            <Grid item>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant={"subtitle1"} fontWeight={700}>
                      Edit post
                    </Typography>
                    {error && <p>{error}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      variant="outlined"
                      name={"title"}
                      value={title}
                      required
                      onChange={(e) => setTitle(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {previousContent && (
                      <Editor
                        onContentChange={handleContentChange}
                        previousContent={previousContent}
                        setIsEmpty={setIsEmpty}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ xs: "stretched", sm: "center" }}
                      justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                      width={1}
                      margin={"0 auto"}
                    >
                      <Box
                        marginBottom={{ xs: 1, sm: 0 }}
                        marginRight={{ sm: 1 }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch />}
                            label="Private"
                            checked={privatePost}
                            onChange={(e) => setPrivatePost(e.target.checked)}
                          />
                        </FormGroup>
                      </Box>
                      <Button
                        size={"large"}
                        color={"secondary"}
                        variant={"contained"}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size={"large"}
                        variant={"contained"}
                        type={"submit"}
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
