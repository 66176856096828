import React from "react";
import PropTypes from "prop-types";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

export default function CommentViewer({ content, contentKey }) {
  CommentViewer.propTypes = {
    content: PropTypes.any.isRequired,
    contentKey: PropTypes.any,
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: (typeof content === "object" && content) || " ",
    editable: false,
    // editorProps: {
    //   attributes: {
    //     class: "viewer",
    //   },
    // },
  });
  console.log(content);

  if (!contentKey) {
    return <EditorContent editor={editor} />;
  }
  return <EditorContent editor={editor} key={contentKey} />;
}
